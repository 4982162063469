<template>
    <Header> </Header>
    <div class="container">
        <div class="page_title m1620">
            <div class="fs15">SIGNEO SELECT</div>
            <div class="fs64 fwb">诗珑产品</div>
        </div>
        <div class="flex-box navigator_box m1620">
            <router-link :to="{ name: 'series' }" class="item ">诗珑严选</router-link>
            <router-link :to="{ name: 'illustrate' }" class="item">施工说明</router-link>
            <router-link :to="{ name: 'formula' }" class="item active">调色配方</router-link>
        </div>
        <div class="m1620">
            <div class="flex-box flex-col-start tool_box">
                <div>
                    <div class="relative mb25">
                        <div class="input_box flex-box select_box z10" :class="state.showColor ? 'active' : ''">
                            <input type="text" class="col3 fs14" placeholder="请输入色号" v-model="colorNum"
                                @input="inputColorNum" />
                        </div>
                        <div class="pro_list z9" :class="state.showColor ? 'show shadow' : ''">
                            <div class="pros" @scroll="scrollColor">
                                <div class="item" v-for="(item, index) in colorList" :key="index"
                                    @click="chooseColor(item.rgb_title_cn, item.id)">
                                    {{ item.rgb_title_cn }}
                                </div>
                                <div v-if="state.colorLoading" class="tc">加载中</div>
                            </div>
                        </div>
                    </div>
                    <div class="relative mb25">
                        <div class="input_box flex-box select_box z8" :class="state.showGoods ? 'active' : ''"
                            @click="state.showGoods = !state.showGoods">
                            <div class="flex-grow-1" :class="series == '' ? 'col85' : 'col3'">
                                {{ series == "" ? "选择产品" : series }}
                            </div>
                        </div>
                        <div class="pro_list z7" :class="state.showGoods ? 'show shadow' : ''">
                            <div class="pros" @scroll="scrollGoods">
                                <div class="item" v-for="(item, index) in goodsSeries" :key="index"
                                    @click="chooseSeries(index, item.id)">
                                    {{ item.goods_name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-box weight">
                        <div class="input_box">
                            <input type="text" class="tr" placeholder="请输入" v-model="result.dosage" />
                        </div>
                        <div class="kg">L</div>
                        <div class="length">{{ result.weight }}</div>
                        <div class="kg">Kg</div>
                    </div>
                    <div class="confirm fs16 cursor" @click="sureCalculate">确认</div>
                </div>
                <div class="pl20" v-if="state.showRes">
                    <div class="res_box tc">
                        <div class="flex-box col5c pb20">
                            <div class="color">颜色</div>
                            <div class="flex-grow-1">编号</div>
                            <div class="flex-grow-1">色浆用量（ml）</div>
                            <div class="mite">毫升尺（ml）</div>
                        </div>
                        <div class="flex-box col5c pb15">
                            <div class="mite flex-box">
                                <div class="flex-grow-1">黑尺</div>
                                <div class="flex-grow-1">红尺</div>
                            </div>
                        </div>
                        <template v-if="result.colors">
                            <div class="flex-box col3 data_item" v-for="(item, index) in result.colors" :key="index">
                                <div class="color">
                                    <div :style="{ backgroundColor: 'rgb(' + item.pastes.paste_r + ',' + item.pastes.paste_g + ',' + item.pastes.paste_b + ')' }"
                                        class="circle"></div>
                                </div>
                                <div class="flex-grow-1">{{ item.pastes.name }}</div>
                                <div class="flex-grow-1">{{ item.paste_dosage }}</div>
                                <div class="mite flex-box">
                                    <div class="flex-grow-1">{{ item.black }}</div>
                                    <div class="flex-grow-1">{{ item.red }}</div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="col83 fs14 tr">屏幕显色与实际颜色有明显区别，选色调色请以诗珑标准色为准。</div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute()
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "调色配方_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});

// 输入色值
let colorNum = ref('');//色值
let colorList = ref([]);
let colorId = ref(0);
let state = reactive({
    showGoods: false,
    showColor: false,
    colorLoading: false,
    goodsLoading: false,
    showRes: false,
});
let isColorFinish = ref(false);//加载完毕
let colorPage = 1;
const inputColorNum = () => {
    if (colorNum.value == '') {
        state.showColor = false;
        state.showGoods = false;
    } else {
        state.showColor = true;
        state.showGoods = false;
        goodsSeries.value = [];
        series.value = '';
        getColor(1);
    }
}
const getColor = async (e) => {
    try {
        const res = await api.getColorCardsList({
            page: e ? e : 1,
            keywords: colorNum.value,
        });
        if (res.data.current_page == 1) {
            colorList.value = [];
        }
        colorList.value = colorList.value.concat(res.data.data);
        state.colorLoading = false;
        if(res.data.current_page == res.data.last_page){
            isColorFinish.value = true;
        }
    } catch (error) {
        console.error(error);
    }
}
const scrollColor = (e) => {
    const clientHeight = e.target.clientHeight;
    const scrollHeight = e.target.scrollHeight;
    const scrollTop = e.target.scrollTop;
    if (scrollTop + clientHeight >= scrollHeight - 54) {
        if (!state.colorLoading && !isColorFinish.value) {
            state.colorLoading = true;
            colorPage += 1;
            getColor(colorPage);
        }
    }
}
const chooseColor = (val, id) => {
    colorNum.value = val;
    state.showColor = false;
    colorId.value = id;
    getGoods(1);
}
// 选择产品
let goodsSeries = ref([]);
let series = ref("");
let goodsId = ref(0);//产品id
let isGoodsFinish = ref(false);//是否加载完毕
let goodsPage = 1;
const getGoods = async (e) => {
    try {
        const res = await api.getFormulaGoodsList({
            page: e ? e : 1,
            color_id: colorId.value,
        });
        if (res.data.current_page == 1) {
            goodsSeries.value = [];
        }
        goodsSeries.value = goodsSeries.value.concat(res.data.data);
        state.goodsLoading = false;
        if (res.data.current_page == res.data.last_page) {
            isGoodsFinish.value = true;
        }
    } catch (error) {
        console.error(error);
    }
}
const scrollGoods = (e) => {
    const clientHeight = e.target.clientHeight;
    const scrollHeight = e.target.scrollHeight;
    const scrollTop = e.target.scrollTop;
    if (scrollTop + clientHeight >= scrollHeight - 54) {
        if (!state.goodsLoading && !isGoodsFinish.value) {
            state.goodsLoading = true;
            goodsPage += 1;
            getGoods(goodsPage);
        }
    }
}
const chooseSeries = (index, id) => {
    series.value = goodsSeries.value[index].goods_name;
    goodsId.value = id;
    state.showGoods = false;
    calculateFnc();
}
let result = ref({});//计算结果
// 配方计算
const calculateFnc = async () => {
    try {
        const res = await api.Calculate({
            color_id: colorId.value,
            goods_id: goodsId.value,
        });
        if (res.code == 1) {
            state.showRes = true;
            result.value = res.data;
        }
    } catch (error) {
        console.error(error);
    }
}
// 点击计算
const sureCalculate = async () => {
    try {
        const res = await api.Calculate({
            color_id: colorId.value,
            goods_id: goodsId.value,
            litres: result.value.dosage
        });
        if (res.code == 1) {
            result.value = res.data;
        }
    } catch (error) {
        console.error(error);
    }
}
</script>

<style lang="less" scoped>
.z10 {
    z-index: 10;
}

.z9 {
    z-index: 9;
}

.z8 {
    z-index: 8;
}

.z7 {
    z-index: 7;
}

.goods_list {

    .goods_item {
        box-shadow: 0 3.6px 47px rgba(0, 0, 0, 0.08);
        width: 288px;
        height: 344px;
        border-radius: 28px;
        background: #fffefd;
        margin-right: 15px;
        margin-bottom: 15px;
        padding: 16px 18px 0;
        position: relative;
        overflow: hidden;

        .to_detail {
            width: 88px;
            height: 24px;
            border: 1px solid #c5c4c4;
            color: #c5c4c4;
            line-height: 22px;
            text-align: center;
            font-size: 10px;
            border-radius: 100px;
            margin-top: 12px;
        }

        .pic {
            width: 400px;
            height: auto;
            position: absolute;
            right: -90px;
            bottom: -108px;
            max-width: inherit;
        }

        .view {
            position: absolute;
            left: 18px;
            bottom: 15px;
            font-size: 10px;
            color: #b2b2b2;

            &::before {
                content: "";
                display: block;
                background: url("../../assets/static/icon/icon_view.png") no-repeat center;
                background-size: 100%;
                width: 19px;
                height: 11px;
                margin-right: 5px;
            }
        }
    }
}

.intro_list {

    .intro_item {
        width: 320px;
        height: 320px;
        border-radius: 50px;
        background: #fefefe;
        margin-right: 15px;
        margin-bottom: 15px;
        box-shadow: 0 3.3px 43px rgba(0, 0, 0, 0.05);

        .pic {
            width: 320px;
            height: 180px;
            display: block;
            object-fit: cover;
            border-radius: 50px 50px 0 0;
        }

        .info {
            padding: 12px 26px 0;

            .title {
                line-height: 30px;
                height: 60px;
            }
        }

        .avatar {
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }

        .detail::after {
            content: "";
            display: block;
            width: 6px;
            height: 10.5px;
            background: url("../../assets/static/icon/icon_arrow-r.png") no-repeat center;
            background-size: 100%;
            margin-left: 7px;
        }
    }
}

.tool_box {

    .input_box {
        width: 595px;
        height: 60px;
        border-radius: 18px;
        background: #fff;
        box-shadow: 0 20px 13.7px rgba(0, 0, 0, 0.04);
        padding: 0 24px;
        position: relative;

        input {
            height: 60px;
            width: 100%;
            font-size: 14px;
        }
    }

    .select_box {
        cursor: pointer;

        &::after {
            content: "";
            display: block;
            width: 17.5px;
            height: 19.5px;
            background: url("../../assets/static/icon/icon_arrow-l.png") no-repeat center;
            background-size: 100%;
            transition: all ease 0.5s;
        }

        &.active::after {
            transform: rotate(-90deg);
        }
    }

    .pro_list {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: #fff;
        border-radius: 18px;
        padding-right: 5px;
        transition: all ease-in .5s;

        .pros {
            height: 0;
            overflow-y: scroll;
            transition: all ease .5s;
        }

        &.show {
            padding-top: 80px;

            .pros {
                height: 270px;
            }
        }

        .pros::-webkit-scrollbar {
            display: block;
        }

        .pros::-webkit-scrollbar {
            width: 12px;
        }

        .pros::-webkit-scrollbar-thumb {
            background-color: #ababab;
            border-radius: 10px;
        }

        .item {
            padding: 0 24px;
            height: 54px;
            line-height: 54px;
            font-size: 16px;
            color: #4b4b4b;
            cursor: pointer;

            &:hover {
                background: #F1F1F1;
            }
        }
    }

    .weight {
        position: relative;
        z-index: 1;

        .input_box {
            width: 100px;
        }

        .kg {
            width: 70px;
            height: 60px;
            border-radius: 18px;
            background: #f5f5f5;
            text-align: center;
            line-height: 60px;
            font-style: 18px;
            color: #333;
            margin-left: 10px;
        }

        .length {
            width: 142px;
            height: 60px;
            border-radius: 18px;
            background: #f5f5f5;
            text-align: right;
            line-height: 60px;
            font-style: 18px;
            color: #333;
            margin-left: 10px;
            padding-right: 14px;
        }
    }

    .confirm {
        width: 164px;
        height: 65px;
        line-height: 65px;
        text-align: center;
        color: #fff;
        background: #333;
        margin-top: 48px;
        border-radius: 18px;
        cursor: pointer;
    }

    .res_box {
        width: 552px;
        padding: 46px 35px;
        border-radius: 18px;
        background: #fff;
        margin-bottom: 20px;
        box-shadow: 0 20px 11.1px rgba(0, 0, 0, 0.04);

        .color {
            width: 50px;

            .circle {
                width: 46px;
                height: 46px;
                border-radius: 50%;
            }
        }

        .mite {
            width: 158px;
            margin-left: auto;
        }
    }

    .data_item {
        padding-bottom: 23px;
    }

    .data_item:last-child {
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 980px) {
    .goods_list {
        flex-wrap: wrap;

        .goods_item {
            width: calc((100vw - 45px) / 2);
            padding: 15px;
            height: auto;
            border-radius: 10px;

            .colc.fs10.pb5 {
                font-size: 8px;
            }

            .col3.fs14 {
                font-size: 9px;
            }

            .col3.fs22.fwb.m-ellipsis {
                font-size: 14px;
            }

            .to_detail {
                width: 65px;
                height: 18px;
                line-height: 16px;
                font-size: 8px;
            }

            &:nth-of-type(2n) {
                margin-right: 0;
            }

            .pic {
                height: auto;
                position: relative;
                max-width: 100%;
                right: 0;
                bottom: 0;
                width: 100%;
            }

            .view {
                font-size: 8px;
            }

            .view::before {
                width: 9.5px;
                height: 5.5px;
            }
        }
    }

    .intro_list {
        flex-wrap: wrap;

        .intro_item {
            width: calc((100vw - 45px)/2);
            height: auto;
            border-radius: 10px;
            margin-right: 0;

            .pic {
                border-radius: 10px 10px 0 0;
                width: 100%;
                height: 90px;
            }

            .info {
                padding: 10px 8px;

                .title {
                    font-size: 10px;
                    line-height: 12px;
                    height: 24px;
                }
            }

            .pt15 {
                padding-top: 5px;
            }

            .avatar {
                width: 20px;
                height: 20px;
            }

            .m-ellipsis,
            .detail {
                font-size: 8px;
            }

            .m-ellipsis {
                padding-left: 3px;
            }

            .detail::after {
                width: 3px;
                height: 6px;
                margin-left: 3px;
            }
        }
    }

    .tool_box {
        display: block;
        padding-top: 0;

        .select_box {
            &::after {
                width: 9px;
                height: 10px;
            }
        }

        .input_box {
            width: auto;
            padding: 0 15px;
            height: 40px;
            border-radius: 13px;
            font-size: 10px;

            input {
                height: 40px;
                font-size: 10px;
            }
        }

        .weight {

            .kg,
            .length {
                height: 40px;
                line-height: 40px;
                border-radius: 13px;
                font-size: 10px;
            }
        }

        .pro_list {
            &.show {
                padding-top: 60px;
            }

            .item {
                height: 40px;
                line-height: 40px;
                font-size: 10px;
            }
        }

        .confirm {
            margin: 30px auto;
            width: 100px;
            height: 40px;
            line-height: 40px;
            font-size: 10px;
            border-radius: 13px;
        }

        .pl20 {
            padding: 0;
        }

        .res_box {
            font-size: 10px;
            width: auto;
            padding: 30px 15px;

            .mite {
                width: 100px;
            }
        }

        .tr {
            text-align: center;
        }
    }

    .pro_list.shadow {
        box-shadow: 0 20px 13.7px rgba(0, 0, 0, 0.04);
    }
}

input.col3::placeholder {
    color: #858585;
}</style>