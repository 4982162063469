<template>
    <Header> </Header>
    <div class="container">
        <div class="page_title m1620">
            <div class="fs15">SIGNEO SELECT</div>
            <div class="fs64 fwb">诗珑产品</div>
        </div>
        <div class="flex-box navigator_box m1620">
            <router-link :to="{ name: 'series' }" class="item active">诗珑严选</router-link>
            <router-link :to="{ name: 'illustrate' }" class="item ">施工说明</router-link>
            <router-link :to="{ name: 'formula' }" class="item ">调色配方</router-link>
        </div>
        <div class="m1620">
            <div class="goods_types flex-box">
                <div class="item" :class="oneIndex == index ? 'active' : ''" @click="changeOneNav(index, item.id)"
                    v-for="(item, index) in categoryOne" :key="index">{{ item.category_name }}</div>
            </div>
            <div class="goods_types flex-box">
                <div class="item" :class="twoIndex == index ? 'active' : ''" @click="changeTwoNav(index, item.id)"
                    v-for="(item, index) in categoryTwo" :key="index">{{ item.category_name }}</div>
            </div>
            <div class="flex-box flex-wrap flex-col-start goods_list" v-if="goodsList.length > 0">
                <router-link :to="{ name: 'productDetail', query: { id: item.id } }" class="goods_item shadow"
                    v-for="(item, index) in goodsList" :key="index">
                    <div class="colc fs10 pb5">{{ item.series.series_name_en }}</div>
                    <div class="col3 fs14">{{ item.series.series_name_cn }}</div>
                    <div class="col3 fs22 fwb m-ellipsis">{{ item.goods_name }}</div>
                    <!-- <div class="col3 fs22 fwb m-ellipsis-l2" style="height:66px;line-height:33px;">{{ item.goods_name }}</div> -->
                    <div class="to_detail">查看详情 >></div>
                    <img :src="item.image" class="pic" alt="" />
                    <div class="view flex-box">{{ item.views }}</div>
                </router-link>
            </div>
            <div class="noData" v-else>
                <img :src="emptyImg" alt="">
            </div>
            <div class="page_box">
                <Pagination v-if="goodsTotal > goodsPageSize" :sumCount="goodsTotal" :perCount="goodsPageSize"
                    @pageTurn="getGoodsList" class="pagination"></Pagination>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute } from 'vue-router'
const route = useRoute()
//设置meta和标题
import { useHead } from "@unhead/vue";
let headObj = {
    title: "产品体系_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
};
useHead(headObj);
let categoryOne = ref([]);
let oneIndex = ref(0); //一级分类下标
let oneId = ref(0);//一级分类id
let categoryTwo = ref([]);
let twoIndex = ref(0); //二级分类下标
let twoId = ref(0); // 二级分类id
let goodsList = ref([]);
let goodsTotal = ref(0);
let goodsPageSize = ref(0);
// 接收传递过来的下标
onMounted(() => {
    if (localStorage.getItem('isLoaded')) {
        categoryOne.value = JSON.parse(localStorage.getItem("categoryOne"));
        oneIndex.value = JSON.parse(localStorage.getItem("oneIndex"));
        oneId.value = JSON.parse(localStorage.getItem("oneId"));
        categoryTwo.value = JSON.parse(localStorage.getItem("categoryTwo"));
        twoIndex.value = JSON.parse(localStorage.getItem("twoIndex"));
        twoId.value = JSON.parse(localStorage.getItem("twoId"));
        goodsList.value = JSON.parse(localStorage.getItem("goodsList"));
        console.log(goodsList.value)
        goodsTotal.value = JSON.parse(localStorage.getItem("goodsTotal"));
        goodsPageSize.value = JSON.parse(localStorage.getItem("goodsPageSize"));
        return false;
    }
    getCategoryOne();
})
const emptyImg = JSON.parse(localStorage.getItem("emptyImg"));
// 一级分类
// 修改一级分类下标
const changeOneNav = (index, id) => {
    oneIndex.value = index;
    twoIndex.value = 0;
    oneId.value = id;
    localStorage.setItem('oneIndex', JSON.stringify(index));
    localStorage.setItem('twoIndex', JSON.stringify(0));
    localStorage.setItem('oneId', JSON.stringify(id));
    getCategoryTwo(oneId.value);
}
const getCategoryOne = async () => {
    try {
        const res = await api.getCategory();
        categoryOne.value = res.data;
        localStorage.setItem('categoryOne', JSON.stringify(res.data));
        oneId.value = res.data[oneIndex.value].id;
        localStorage.setItem('oneId', JSON.stringify(oneId.value));
        if (res.data.length == 0) {
            getGoodsList();
        } else {
            getCategoryTwo(categoryOne.value[oneIndex.value].id);
        }
    } catch (error) {
        console.error(error);
    }
}
// 二级分类

// 修改二级分类下标
const changeTwoNav = (index, id) => {
    twoIndex.value = index;
    twoId.value = id;
    localStorage.setItem('twoIndex', JSON.stringify(index));
    localStorage.setItem('twoId', JSON.stringify(id));
    getGoodsList();
}
const getCategoryTwo = async (id) => {
    try {
        const res = await api.getSecoond({
            category_id: id
        });
        if (res.data.length > 0) {
            categoryTwo.value = res.data;
            twoId.value = res.data[twoIndex.value].id;
            localStorage.setItem('categoryTwo', JSON.stringify(categoryTwo.value));
            localStorage.setItem('twoId', JSON.stringify(twoId.value));
        } else {
            categoryTwo.value = [];
            twoId.value = 0;
        }
        getGoodsList();

    } catch (error) {
        console.error(error);
    }
}
// 商品列表

const getGoodsList = async (e) => {
    try {
        const res = await api.getGoodsList({
            category_id: oneId.value,
            second_id: twoId.value,
            page: e ? e + 1 : 1
        });
        goodsList.value = [];
        goodsList.value = res.data.data;
        localStorage.setItem('goodsList', JSON.stringify(res.data.data));
        goodsPageSize.value = res.data.per_page;
        localStorage.setItem('goodsPageSize', JSON.stringify(res.data.per_page));
        goodsTotal.value = res.data.total;
        localStorage.setItem('goodsTotal', JSON.stringify(res.data.total));
        localStorage.setItem('isLoaded', true);
    } catch (error) {
        console.error(error);
    }
}
</script>

<style lang="less" scoped>
.goods_list {

    .goods_item {
        box-shadow: 0 3.6px 47px rgba(0, 0, 0, 0.08);
        width: 288px;
        height: 344px;
        border-radius: 28px;
        background: #fffefd;
        margin-right: 15px;
        margin-bottom: 15px;
        padding: 16px 18px 0;
        position: relative;
        overflow: hidden;

        .to_detail {
            width: 88px;
            height: 24px;
            border: 1px solid #c5c4c4;
            color: #c5c4c4;
            line-height: 22px;
            text-align: center;
            font-size: 10px;
            border-radius: 100px;
            margin-top: 12px;
        }

        .pic {
            width: 400px;
            height: auto;
            position: absolute;
            right: -90px;
            bottom: -108px;
            max-width: inherit;
        }

        .view {
            position: absolute;
            left: 18px;
            bottom: 15px;
            font-size: 10px;
            color: #b2b2b2;

            &::before {
                content: "";
                display: block;
                background: url("../../assets/static/icon/icon_view.png") no-repeat center;
                background-size: 100%;
                width: 19px;
                height: 11px;
                margin-right: 5px;
            }
        }
    }
}

@media only screen and (max-width: 980px) {
    .goods_list {
        flex-wrap: wrap;

        .goods_item {
            width: calc((100vw - 45px) / 2);
            padding: 15px;
            height: auto;
            border-radius: 10px;

            .colc.fs10.pb5 {
                font-size: 8px;
            }

            .col3.fs14 {
                font-size: 9px;
            }

            .col3.fs22.fwb.m-ellipsis {
                font-size: 14px;
            }

            .to_detail {
                width: 65px;
                height: 18px;
                line-height: 16px;
                font-size: 8px;
            }

            &:nth-of-type(2n) {
                margin-right: 0;
            }

            .pic {
                height: auto;
                position: relative;
                max-width: 100%;
                right: 0;
                bottom: 0;
                width: 100%;
            }

            .view {
                font-size: 8px;
            }

            .view::before {
                width: 9.5px;
                height: 5.5px;
            }
        }
    }
}

.container {
    .links {
        padding-top: 100px;
    }

    .detail_box {
        padding-bottom: 100px;

    }

    .goods_info {
        min-height: calc(100vh - 80px);
    }


    .goods_box {

        .icon_back {
            width: 48px;
            height: 48px;
            display: block;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: -150px;
            bottom: 0;
            margin: auto;
        }

        .series_name {
            color: rgba(51, 51, 51, .45);
        }

        .series {
            color: rgba(51, 51, 51, .8);
        }

        .pic {
            margin-right: 40px;
            width: 384px;
            height: auto;
            display: block;
        }

        .size {
            height: 14px;
            border: 1px solid rgba(51, 51, 51, .8);
            color: rgba(51, 51, 51, .8);
            font-size: 10px;
            text-align: center;
            line-height: 12px;
            border-radius: 100px;
            padding: 0 6px;
            display: inline-block;
            margin-left: 5px;
        }

        .title {
            font-size: 52px;
            color: #333;
            font-weight: bold;
        }

        .features {
            img {
                width: 100px;
                height: auto;
                // margin-right: 10px;
                display: block;

                &:nth-of-type(1) {
                    margin-left: -10px;
                }
            }
        }

        .targets {
            padding: 12px 0 24px;

            .item {
                height: 25px;
                border: 1px solid rgba(51, 51, 51, .45);
                color: rgba(51, 51, 51, .45);
                padding: 0 15px;
                font-size: 13px;
                border-radius: 100px;
                line-height: 23px;
                margin-right: 10px;
            }
        }

        .buy {
            border: 1px solid #333;
            color: #333;
            width: 110px;
            height: 45px;
            border-radius: 22px;
            margin-right: 15px;
            font-size: 13px;
            transition: all ease .5s;
            box-shadow: 0px 3.48px 6.09px 0px rgba(0, 0, 0, 0.07);


            &.tao::before {
                content: "";
                display: block;
                width: 21px;
                height: 22px;
                background: url("../../assets/static/icon/icon_tao.png") no-repeat center;
                background-size: 100%;
                margin-right: 5px;
                transition: all ease .5s;
            }

            &.jd::before {
                content: "";
                display: block;
                width: 25px;
                height: 26px;
                background: url("../../assets/static/icon/icon_jd.png") no-repeat center;
                background-size: 100%;
                margin-right: 5px;
                transition: all ease .5s;
            }

            &:hover {
                background-color: #333;
                color: #f9f9f9;
            }

            &.tao:hover::before {
                background-image: url("../../assets/static/icon/icon_tao-w.png");
            }

            &.jd:hover::before {
                background-image: url("../../assets/static/icon/icon_jd-w.png");
            }
        }

        .progress {
            width: 230px;
            height: 48px;
            font-size: 12px;
            color: rgba(51, 51, 51, .7);
            cursor: pointer;

            &::before {
                display: block;
                content: "";
                width: 25px;
                height: 25px;
                background: url("../../assets/static/icon/icon_wx.png") no-repeat center;
                background-size: 100%;
                margin-right: 10px;
            }

            &:hover .code_box {
                display: block;
            }
        }

        .code_box {
            display: none;
            background: #fff;
            border-radius: 10px;
            width: 220px;
            height: 220px;
            padding: 10px;
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            margin: auto;

            img {
                width: 200px;
                height: 200px;
                display: block;
            }

            &::before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 10px 10px;
                border-color: transparent transparent #fff;
                position: absolute;
                top: -8px;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }
}

@media only screen and (max-width: 980px) {
    .container {


        .goods_info {
            min-height: auto;
            padding: 50px 0;
        }

        .links {
            padding: 30px 0;
        }

        .detail_box {
            padding: 0;

            .goods_box {
                padding: 0;
                display: block;

                .icon_back {
                    left: 30px;
                    top: 130px;
                    width: 24px;
                    height: 24px;
                    bottom: auto;
                }

                .pic {
                    width: 300px;
                    margin: 0 auto 30px;
                    display: block;
                }

                .info {
                    width: 100vw;
                    padding: 0 15px;
                    text-align: center;

                    .series_name {
                        font-size: 10px;
                    }

                    .series {
                        font-size: 17px;
                        justify-content: center;
                    }
                }

                .title {
                    font-size: 36px;
                }

                .features {
                    flex-wrap: wrap;

                    img {
                        margin-left: 0;
                        width: 20%;
                    }
                }

                .targets {
                    flex-wrap: wrap;
                    justify-content: center;

                    .item {
                        font-size: 9px;
                        padding: 0 11px;
                        margin-bottom: 10px;
                        width: auto;
                        height: 32px;
                        line-height: 30px;
                    }
                }

                .buy_box {
                    justify-content: center;

                    .buy {
                        width: 77px;
                        height: 31px;
                        font-size: 9px;
                    }

                    .buy.jd::before {
                        width: 15px;
                        height: 15px;
                    }

                    .buy.tao::before {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
}
</style>