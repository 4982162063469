import { createApp, provide } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/css/reset.css";
import Header from "../src/components/header.vue";
import Footer from "../src/components/footer.vue";
import Pagination from "../src/components/pagination.vue";
import popup from "../src/components/popup.vue";
import * as api from "./request/api.js"; // 导入api接口
// import '@/utils/rem.js';
import { createHead } from "@unhead/vue";
import ElementPlus from "element-plus";
import 'element-plus/dist/index.css';
const app = createApp(App);
const head = createHead();
app.use(head);
// app.config.productionTip = false; // 关闭生产提示
app.component("Header", Header);
app.component("Footer", Footer);
app.component("Pagination", Pagination);
app.component("popup", popup);
// 使用provide提供接口
app.provide("api", api);
app.use(ElementPlus);
app.use(store).use(router).mount("#app");
