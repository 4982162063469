<template>
    <Header></Header>
    <div class="container">
        <div class="page_title m1620">
            <div class="fs15">SIGNEO COLOR</div>
            <div class="fs64 fwb">诗珑色彩</div>
        </div>
        <div class="flex-box navigator_box m1620">
            <router-link :to="{ name: 'trends' }" class="item ">色彩趋势</router-link>
            <router-link :to="{ name: 'card' }" class="item">色卡查询</router-link>
            <router-link :to="{ name: 'match' }" class="item active">色系搭配</router-link>
            <router-link :to="{ name: 'guide' }" class="item">风格指南</router-link>
        </div>
        <div class="m1920">
            <div class="collocation flex-box flex-center">
                <div class="item" @click="chooseDa(index)" v-for="(item, index) in colorSchemes" :key="index">
                    <div class="top">
                        <img :src="item.image" class="icon_coll" alt="">
                        <div class="mask flex-box flex-center">
                            <img src="../../assets/static/icon/icon_finger.png" alt="">
                        </div>
                        <div class="flex-box">
                            <div class="color_item flex-grow-1" v-for="(items, index) in item.colors" :key="index"
                                :style="{ background: '#' + items.color_rgb }"></div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="daData.length > 0">
                <div class="example_box flex-center flex-box flex-col-end" :class="status.showDa ? 'show' : ''">
                    <img :src="daData[seriesIndex].image + '?x-oss-process=image/resize,m_lfit,w_674,h_494'" class="pic" alt="">
                    <div class="color_example">
                        <div class="item" v-for="(item, index) in daData" :class="seriesIndex == index ? 'active' : ''"
                            :key="index" @click="seriesIndex = index"
                            :style="{ 'background': '#' + item.color_rgb, 'top': 75 * index + 'px' }"></div>
                        <div class="color_name tc"
                            :style="{ 'background': '#' + daData[seriesIndex].color_rgb, 'top': (75 * seriesIndex - 4) + 'px' }">
                            <span>{{ daData[seriesIndex].color_no }}</span><span class="pl15">{{
                                daData[seriesIndex].color_name
                            }}</span>
                        </div>
                        <div class="hide flex-box cursor" @click="status.showDa = false, seriesIndex = 0">收起</div>
                    </div>
                </div>
            </template>
        </div>

    </div>
    <Footer></Footer>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute();
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "色系搭配_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});
// 接收传递过来的下标
onMounted(() => {
    colorScheme();
})
// 修改下标
onBeforeRouteUpdate((to) => {
    colorScheme();
});



// 点击显示色系搭配
let status = reactive({ showDa: false });
let colorSchemes = ref([]);
// 某一色系搭配小分类的下标
let seriesIndex = ref(0);
// 某一色系搭配的小分类
let daData = ref([]);
// 色系搭配
const colorScheme = async () => {
    try {
        const res = await api.getColorScheme(); // 调用接口
        colorSchemes.value = res.data.data;
        daData.value = colorSchemes.value[0].colors;
    } catch (error) {
        console.error(error);
    }
}
const chooseDa = (index) => {
    status.showDa = true;
    daData.value = colorSchemes.value[index].colors;
    console.log(daData.value)
    seriesIndex.value = 0;
}
</script>


<style lang="less" scoped>
.collocation {
    padding: 120px 0 100px;

    .item {
        width: 270px;
        border-radius: 18px;
        overflow: hidden;
        margin: 0 22.5px;
        cursor: pointer;

        .top {
            position: relative;
            border-radius: 18px;
            overflow: hidden;

            .icon_coll {
                width: 100%;
                height: 233px;
                position: relative;
                z-index: 1;
                display: block;
                object-fit: cover;
            }

            .mask {
                opacity: 0;
                width: 100%;
                height: 233px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                z-index: 2;
                background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .2) 50%, rgba(0, 0, 0, .5));
                transition: all ease .5s;

                img {
                    width: 67px;
                    height: 82px;
                }
            }
        }

        .color_item {
            height: 46px;
        }

        &:hover .mask {
            opacity: 1;
        }
    }
}

.example_box {
    height: 0;
    overflow: hidden;
    transition: height ease-in .5s;

    &.show {
        height: 510px;
    }

    .pic {
        width: 674px;
        height: 494px;
    }

    .color_example {
        margin-left: 56px;
        position: relative;
        width: 170px;
        height: 494px;

        .item {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-bottom: 34px;
            cursor: pointer;
            position: absolute;
            left: 0;
            transition: all ease .5s;

            &.active {
                transform: scale(1.2);
            }
        }

        .color_name {
            position: absolute;
            left: 62px;
            border-radius: 100px;
            width: 143px;
            height: 48px;
            line-height: 48px;
            font-size: 10px;
            color: #fff;
            transition: top ease .5s;
        }

        .hide {
            position: absolute;
            right: 0;
            bottom: 0;

            &::after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 5px 8px;
                border-color: transparent transparent #000;
                margin-left: 5px;
            }
        }

    }
}

@media only screen and (max-width: 980px) {
    .collocation {
        padding: 0;
        width: 100%;
        flex-wrap: wrap;

        .item {
            margin: 0 15px 15px;
            width: 135px;

            .top {
                .icon_coll {
                    height: 116px;
                }

                .mask {
                    height: 100%;

                    img {
                        width: 30px;
                        height: auto;
                    }
                }
            }

            .color_item {
                height: 23px;
            }
        }
    }

    .example_box {
        display: block;

        &.show {
            height: 800px;
        }

        .pic {
            width: 100%;
            height: auto;
        }

        .color_example {
            margin: 30px auto 60px;
            position: relative;
            height: 330px;

            .hide {
                right: auto;
                bottom: -60px;
                left: 65px;
            }
        }
    }
}
</style>