<template>
    <Header></Header>
    <div class="container">
        <div class="page_title m1620">
            <div class="fs15">SIGNEO TOOLS</div>
            <div class="fs64 fwb">诗珑工具</div>
        </div>
        <div class="flex-box navigator_box m1620">
            <div class="flex-box">
                <router-link :to="{ name: 'calculate' }" class="item ">漆量计算</router-link>
                <router-link :to="{ name: 'shop' }" class="item active">门店查询</router-link>
                <router-link :to="{ name: 'security' }" class="item ">防伪自查</router-link>
            </div>
            <div class="flex-box flex-grow-1 flex-end search_box">
                <input type="text" class="search_input" placeholder="搜你所想..." v-model="keywords">
                <div class="search cursor" @click="getShopList(0)"></div>
            </div>
        </div>
        <div class="m1620">
            <div class="shop_box">
                <div class="flex-box area_box ">
                    <div class="area" :class="areaIndex == -1 ? 'colb3' : ''" @click.stop="showArea = !showArea">{{
                        areaIndex == -1 ? '请选择地区...' :
                        areaList[areaIndex].areas.short_name }}</div>
                    <div class="arr_box flex-box flex-center" :class="showArea ? 'active' : ''"
                        @click.stop="showArea = !showArea"></div>
                    <div class="area_list flex-box flex-col-start flex-wrap" v-if="showArea">
                        <div class="item" v-for="(item, index) in areaList" :key="index" @click.stop="chooseArea(index)">{{
                            item.areas.short_name }}</div>
                    </div>
                </div>
                <div class="goods_types flex-box">
                    <div class="item" :class="categoryId == 0 ? 'active' : ''" @click="changeCateId(0)">全部</div>
                    <div class="item" :class="categoryId == item.id ? 'active' : ''" v-for="(item, index) in categoryList"
                        :key="index" @click="changeCateId(item.id)">{{
                            item.category_name }}</div>
                </div>
                <div class="flex-box flex-col-start flex-wrap" v-if="shopList.length > 0">
                    <div class="map_item cursor" v-for="(item, index) in shopList" :key="index" @click="toMap(item)">
                        <img :src="item.shop_image" class="icon_map" alt="">
                        <div class="info">
                            <div class="fs14 fwb m-ellipsis">{{ item.shop_name }}</div>
                            <div class="fs10 m-ellipsis mtb5">{{ item.address_detail }}</div>
                            <div class="flex-box">
                                <div class="flex-grow-1">
                                    <!-- <div class="fs10 pb10">店长：{{ item.shop_manager }}</div> -->
                                    <div class="fs10 colb3">营业时间：{{ item.business_hours }}</div>
                                </div>
                                <img src="../../assets/static/icon/icon_location.png" class="icon_location" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="noData" v-else>
                    <img :src="emptyImg" alt="">
                </div>
                <Pagination v-if="total > pageSize" :page="pageNum" :sumCount="total" :perCount="pageSize"
                    @pageTurn="getShopList" class="pagination"></Pagination>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute()
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "门店查询_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});
// 接收传递过来的下标
onMounted(() => {
    getShopArea();
})
onBeforeRouteUpdate((to) => {
    getShopArea();
});

const emptyImg = JSON.parse(localStorage.getItem("emptyImg"));
let areaList = ref([]);// 地区数据
let categoryList = ref([]);// 分类数据
let categoryId = ref(0);//分类id
const changeCateId = (id) => {
    categoryId.value = id;
    getShopList(0);
}
// 获取省市区和分类
const getShopArea = async () => {
    try {
        const res = await api.getArea(); // 调用接口
        areaList.value = res.data.provinces;
        categoryList.value = res.data.categoryList;
        getShopList(0);
    } catch {
        console.log(error)
    }
}
let areaIndex = ref(-1);// 地区下标
let showArea = ref(false);// 显示地区列表
let provinceId = ref(0);
// 选择地区
const chooseArea = (index) => {
    areaIndex.value = index;
    provinceId.value = areaList.value[index].areas.id;
    showArea.value = false;
    getShopList(0)
}
let keywords = ref('');//关键词
let shopList = ref([]);//门店列表
let total = ref(0);
let pageSize = ref(0);
let pageNum = ref(0);
// 获取门店
const getShopList = async (e) => {
    try {
        pageNum.value = e;
        const res = await api.getShopsList({
            page: e ? e + 1 : 1,
            keywords: keywords.value,
            category_id: categoryId.value,
            province_id: provinceId.value,
        }); // 调用接口
        shopList.value = res.data.data;
        total.value = res.data.total;
        pageSize.value = res.data.per_page;
    } catch {
        console.log(error)
    }
}
// 点击跳转地图
const toMap = (item) => {
    let url = `https://apis.map.qq.com/tools/poimarker?type=0&marker=coord:${item.lat},${item.lng};title:${item.shop_name};addr:${item.address_detail}&key=VSPBZ-POMWA-DTHKG-CFJNK-A3UL5-7XBWU&referer=myapp`
    window.open(url, "_blank");
}

</script>
<style lang="less" scoped>
.shop_box {
    padding: 60px 0 0;

    .area_box {
        cursor: pointer;
        position: relative;

        .area {
            width: 172px;
            height: 53px;
            background: #fff;
            padding: 0 20px;
            line-height: 53px;
        }

        .arr_box {
            background: #fff;
            margin-left: 6px;

            &::after {
                content: "";
                display: block;
                width: 66px;
                height: 53px;
                background: url('../../assets/static/icon/icon_arrow-l.png') no-repeat center;
                background-size: 18px 20px;
                transition: all ease .5s;
            }

            &.active::after {
                transform: rotate(-90deg);
            }
        }

        .area_list {
            position: absolute;
            top: 53px;
            left: 0;
            background: #fff;
            padding: 20px;
            width: 750px;
            z-index: 3;

            .item {
                width: 132px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 15px;
            }
        }
    }



    .goods_types {
        padding-left: 0;
        padding-top: 40px;
        padding-bottom: 88px;
    }

    .map_item {
        width: 273px;
        height: 250px;
        border-radius: 21px;
        background: #fff;
        margin-right: 26px;
        margin-bottom: 22px;
        overflow: hidden;


        .icon_map {
            height: 150px;
            width: 273px;
            object-fit: cover;
            display: block;
        }

        .info {
            padding: 7px 10px 0;
        }

        .icon_location {
            width: 41px;
            height: 40.5px;
            display: block;
        }
    }
}

.search_input {
    width: 180px;
    height: 34px;
    border-radius: 100px;
    border: 1px solid #ABABAB;
    padding: 0 15px;
}

.search {
    width: 64px;
    height: 30px;
    border-radius: 100px;
    background: #343434;
    background-image: url('../../assets/static/icon/icon_search.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 17px 17px;
    margin-left: 24px;
    margin-right: 150px;
}

@media only screen and (max-width: 980px) {
    .navigator_box {
        display: block;

        .search_box {
            width: auto;
            margin-top: 30px;
            justify-content: flex-start;

            .search {
                margin-left: 15px;
                height: 25px;
                margin-right: 0;
            }

            .search_input {
                height: 25px;
                font-size: 10px;
            }
        }
    }

    .shop_box {
        padding: 0;

        .area_box {
            .area {
                height: 40px;
                line-height: 40px;
                font-size: 10px;
            }

            .arr_box {
                height: 40px;
                line-height: 40px;
                font-size: 10px;

                &::after {
                    width: 50px;
                    height: 27px;
                    background-size: 8px 11px;
                }
            }

            .area_list {
                width: 300px;
                padding: 0;

                .item {
                    height: 50px;
                    line-height: 50px;
                    width: 100px;
                    font-size: 10px;
                }
            }
        }

        .goods_types {
            padding: 30px 0;
        }

        .map_item {
            width: calc((100vw - 45px) / 2);
            margin-right: 15px;
            height: auto;
            padding-bottom: 10px;

            .icon_map {
                height: auto;
            }

            &:nth-of-type(2n) {
                margin-right: 0;
            }

            .fs14.fwb.m-ellipsis {
                font-size: 12px;
            }

            .icon_location {
                width: 20px;
                height: auto;
            }
        }
    }
}</style>