<template>
  <router-view />
</template>

<script setup>
import { onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口

// 页面加载 这里相当于onload
onMounted(() => {
  getFootInfo();
  getEmptyImg();
});
const getFootInfo = async () => {
  try {
    const res = await api.getFooter(); // 调用接口
    localStorage.setItem('footInfo', JSON.stringify(res.data));
  } catch (error) {
    console.error(error);
  }
}
const getEmptyImg = async () => {
  try {
    const res = await api.getEmptyImage(); // 调用接口
    localStorage.setItem('emptyImg', JSON.stringify(res.data.empty_image));
    // localStorage.removeItem('emptyImg')
  } catch (error) {
    console.error(error);
  }
}
</script>