<template>
    <div ref="mapContainer" style="width: 100vw; height: 100vh;"></div>
</template>
  
<script setup>
import { ref, reactive, onMounted, inject } from "vue";

const mapContainer = ref(null);
onMounted(() => {
    // 加载腾讯地图 JavaScript API GL
    const script = document.createElement('script');
    script.src = 'https://map.qq.com/api/gljs?v=1.exp&key=VSPBZ-POMWA-DTHKG-CFJNK-A3UL5-7XBWU';
    script.onload = initMap;
    document.head.appendChild(script);
});

const initMap = () => {
    const map = new window.qq.maps.Map(mapContainer.value, {
        center: new window.qq.maps.LatLng(39.916527, 116.397128),
        zoom: 13,
    });

    // 在地图上创建信息窗口
    const infoWin = new window.qq.maps.InfoWindow({
        map: map,
    });

    // 设置信息窗口的内容，可以包含图文信息
    infoWin.setContent('<div><h1>标题</h1><p>这里是信息窗口的详细内容</p></div>');

    // 将信息窗口绑定到指定的位置
    infoWin.setPosition(new window.qq.maps.LatLng(39.916527, 116.397128));

    // 显示信息窗口
    infoWin.open();
};
</script>
  
  