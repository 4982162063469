import { get, post } from "./http";
// 首页
export const getBanner = (p) => get("/index/index", p); // 首页
export const getFooter = (p) => get("/index/getFooter", p); // 底部联系方式

// 色彩服务
export const getColorTrend = (p) => get("/articles/getColorTrend", p); // 色彩趋势
export const getStyleGuide = (p) => get("/articles/getStyleGuide", p); // 风格指南
export const getStyleGuideDeatil = (p) => get("/articles/getStyleGuideDeatil", p); // 风格指南详情
export const getRecommendColorCardList = (p) => get("/color_card/getRecommendColorCardList", p); // 获取推荐色卡
export const getColorCardList = (p) => get("/color_card/getColorCardList", p); // 获取色卡
export const getPDF = (p) => get("/color_card/getPDF", p); // 获取电子色卡资料
export const getColorScheme = (p) => get("/articles/getColorScheme", p); // 色彩搭配
export const getColorCardRes = (p) => get("/color_card/getColorCardRes", p); // 获取推荐色卡与色卡分类

// 产品服务
export const getCategory = (p) => get("/goods/getCategory", p); // 商品一级分类
export const getSecoond = (p) => get("/goods/getSecoond", p); // 商品二级分类
export const getGoodsList = (p) => get("/goods/getGoodsList", p); // 商品列表
export const getGoodsDetail = (p) => get("/goods/getGoodsDetail", p); // 商品详情
export const getConstructionInstructions = (p) => get("/articles/getConstructionInstructions", p); // 施工说明
export const getColorCardsList = (p) => post("/formula/getColorCardsList", p); // 色卡查询
export const getFormulaGoodsList = (p) => post("/formula/getGoodsList", p); // 色卡商品查询
export const Calculate = (p) => post("/formula/Calculate", p); // 配方计算

// 案例
export const getCase = (p) => get("/cases/caseList", p);
export const getCaseDetail = (p) => get("/cases/caseDetail", p);

// 实用工具
export const needKnow = (p) => get("/articles/Information", p); // 用户须知
export const paintCalculate = (p) => get("/index/paintCalculate", p); // 漆量计算
export const getsecurityImage = (p) => get("/index/getsecurityImage", p); // 防伪自查长图
export const getArea = (p) => get("/shops/getArea", p); // 开放省份与分类
export const getShopsList = (p) => get("/shops/getShopsList", p); // 店铺列表
export const saveCooperations = (p) => post("/mine/saveCooperations", p); // 博主合作

// 关于我们
export const getBrands = (p) => get("/articles/getBrands", p); // 品牌故事
export const getCertifications = (p) => get("/articles/getCertifications", p); // 环保认证
export const getContactUs = (p) => get("/index/getContactUs", p); // 联系我们

 // 搜索页面
export const searchList = (p) => get("/index/searchList", p);

// 暂无数据
export const getEmptyImage = (p) => get("/index/getEmptyImage",p)