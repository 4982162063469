<template>
    <Header></Header>
    <div class="container">
        <div class="page_title m1620">
            <div class="fs15">SIGNEO COLOR</div>
            <div class="fs64 fwb">诗珑色彩</div>
        </div>
        <div class="flex-box navigator_box m1620">
            <router-link :to="{ name: 'trends' }" class="item ">色彩趋势</router-link>
            <router-link :to="{ name: 'card' }" class="item">色卡查询</router-link>
            <router-link :to="{ name: 'match' }" class="item ">色系搭配</router-link>
            <router-link :to="{ name: 'guide' }" class="item active">风格指南</router-link>
        </div>
        <div class="m1920">
            <div class="style_box">
                <router-link :to="{ name: 'styleDetail', query: { id: item.id } }" class="item relative"
                    v-for="(item, index) in styleGuides" :key="index"
                    :style="{ backgroundImage: 'url(' + item.image + ')' }">
                    <div class="title_box colf">
                        <div class="fs17">{{ item.enname }}</div>
                        <div class="fs58 fwb">{{ item.name }}</div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute();
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "风格指南_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});
// 接收传递过来的下标
onMounted(() => {
    styleGuide();
})
// 修改下标
onBeforeRouteUpdate((to) => {
    styleGuide();
});

// 风格指南
let styleGuides = ref([]);
const styleGuide = async () => {
    try {
        const res = await api.getStyleGuide(); // 调用接口
        styleGuides.value = res.data.data;
    } catch (error) {
        console.error(error);
    }
}
</script>


<style lang="less" scoped>
.style_box {
    padding: 120px 0 0;

    .item {
        display: block;
        margin-bottom: 50px;
        cursor: pointer;
        height: calc(100vw / 1920 * 530);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        img {
            position: relative;
            z-index: 1;
        }

        .title_box {
            z-index: 2;
            position: absolute;
            top: 36px;
            left: 44px;
        }
    }

}


@media only screen and (max-width: 980px) {
    .style_box {
        padding: 0;

        .item {
            margin-bottom: 30px;

            .title_box {
                top: 15px;
                left: 15px;

                .fs17 {
                    font-size: 12px;
                }

                .fs58 {
                    font-size: 30px;
                }
            }
        }
    }
}
</style>