<template>
    <Header></Header>
    <div class="container">
        <div class="page_title m1620 pl0">
            <div class="fs15">ABOUT US</div>
            <div class="fs64 fwb">关于我们</div>
        </div>
        <div class="flex-box navigator_box m1620 pl0">
            <router-link :to="{ name: 'story' }" class="item ">品牌故事</router-link>
            <router-link :to="{ name: 'authentication' }" class="item active">环保认证</router-link>
            <router-link :to="{ name: 'contact' }" class="item">联系我们</router-link>
            <router-link :to="{ name: 'cooperation' }" class="item">博主合作</router-link>
        </div>
        <div class="m1920">
            <div class="flex-box flex-center flex-wrap auth_box">
                <div class="item" v-for="(item, index) in certificateList" :key="index">
                    <img :src="item.image" class="top" alt="">
                    <div class="plr40">
                        <div class="fs15 col3a title"><span>{{ item.name }}</span></div>
                        <div class="fs10 pt10 detail ">{{ item.detail }}</div>
                    </div>
                </div>
            </div>
            <div class="page_box">
                <Pagination v-if="cerTotal > cerPageSize" :sumCount="cerTotal" :perCount="cerPageSize"
                    @pageTurn="certifications" class="pagination"></Pagination>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>
<script setup>

import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute()
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "环保认证_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});
// 接收传递过来的下标
onMounted(() => {
    certifications();
})
onBeforeRouteUpdate((to) => {
    certifications();
});

// 环保认证
let certificateList = ref([]);
let cerTotal = ref(0);
let cerPageSize = ref(0);
const certifications = async (e) => {
    try {
        const res = await api.getCertifications({
            page: e ? e + 1 : 1,
        }); // 调用接口
        certificateList.value = res.data.data;
        cerTotal.value = res.data.total;
        cerPageSize.value = res.data.per_page;
    } catch (error) {
        console.error(error);
    }
}
</script>
<style lang="less" scoped>
.auth_box {
    width: 970px;
    margin: 0 auto;
    padding: 120px 0 0;

    .item {
        cursor: pointer;
        box-shadow: 15px 3.6px 29px rgba(0, 0, 0, .05) inset;
        width: 284px;
        height: 258px;
        border-radius: 55px;
        background: #fff;
        margin-bottom: 50px;
        margin-right: 50px;
        position: relative;

        .top {
            width: 100%;
            height: 155px;
            object-fit: contain;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            transition: all ease .5s;
        }

        .title {
            padding-top: 155px;
            height: 175px;
            width: 100%;
            position: relative;

            span {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                transition: all ease .5s;
                white-space: nowrap;
            }
        }

        .detail {
            opacity: 0;
            transition: all ease .5s;
        }

        &:hover {
            .top {
                top: -20px;
            }

            .title {
                span {
                    left: 0;
                    transform: translateX(0);
                }
            }

            .detail {
                opacity: 1;
            }
        }

        &:nth-of-type(3n) {
            margin-right: 0;
        }



    }
}

@media only screen and (max-width: 980px) {
    .auth_box {
        width: auto;
        padding: 0;

        .item {
            width: calc((100vw - 45px) / 2);
            margin-right: 15px;
            border-radius: 18px;
            height: auto;

            .plr40 {
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 10px;
            }

            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }
    }

}
</style>