<template>
    <Header></Header>
    <div class="container">
        <div class="detail_list">
            <div class="detail_box m1620" v-for="(item, index) in caseList" :key="index">
                <div class="box">
                    <div class="fs34 fwb">{{ item.title }}</div>
                    <div class="fs16 ptb20 col5b">{{ item.subtitle }}</div>
                    <div class="fs13 intro col3">{{ item.describe }}</div>
                    <div class="flex-box flex-wrap pic_box" :class="item.show ? '' : 'show'">
                        <img :src="items" class="detail" v-for="(items, index) in item.images"
                            @click="showImageDialog(item.images, index)" :key="index" alt="">
                    </div>
                </div>
                <!-- <div class="mask" :class="item.show ? '' : 'show'"></div> -->
                <div class="show_more" :class="item.show ? '' : 'active'" @click="item.show = !item.show"></div>
            </div>
        </div>
        <div class="page_box">
            <Pagination v-if="caseTotal > casePageSize" :page="casePageNum" :sumCount="caseTotal" :perCount="casePageSize"
                @pageTurn="getCases" class="pagination"></Pagination>
        </div>
    </div>
    <Footer></Footer>
    <!-- <el-dialog v-model="state.dialogVisible" width="70%" center padding="0" :style="{ padding: '0' }" :show-close="false"
        class="no-header-dialog">
        <img :src="currentImage" alt="" style="width: 100%;height: auto;display: block;">
    </el-dialog> -->
    <popup :visible="popupVisible" :confirmText="false" :cancelText="false" @close="popupVisible = false, bannerList = []">
        <div class="img_viewer flex-box flex-center">
            <img src="../assets/static/icon/last-w.png" class="actions" alt="" @click="prevSlide">
            <swiper class="swiper" :pagination="false" @swiper="onSwiper" :modules="modules" loop ref="mySwiper"
                :initialSlide="initIndex">
                <swiper-slide v-for="(item, index) in bannerList" :key="index" class="flex-box flex-center">
                    <img :src="item" alt="">
                </swiper-slide>
            </swiper>
            <img src="../assets/static/icon/next-w.png" class="actions" alt="" @click="nextSlide">
        </div>
    </popup>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "案例详情_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});
onMounted(() => {
    getCases();
})
let popupVisible = ref(false);
// 引入swiper
import { A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
const modules = [A11y, Autoplay];

// 设置banner列表
let bannerList = ref([]);
let initIndex = ref(1)
const showImageDialog = (url, index) => {
    bannerList.value = url;
    initIndex.value = index;
    popupVisible.value = true;
}

let mySwiper = ref(null);
const onSwiper = (swiper) => {
    mySwiper.value = swiper; // 保存 Swiper 实例
};

const nextSlide = () => {
    if (mySwiper.value) {
        mySwiper.value.slideNext();
    }
};

const prevSlide = () => {
    if (mySwiper.value) {
        mySwiper.value.slidePrev();
    }
};


let casePageNum = ref(0);
let caseList = ref([]);
let casePageSize = ref(0);
let caseTotal = ref(0);
const getCases = async (e) => {
    try {
        casePageNum.value = e;
        const res = await api.getCase({
            page: e ? e + 1 : 1,
        }); // 调用接口
        caseList.value = [];
        caseList.value = res.data.data;
        // 使用forEach方法给每个对象添加show属性
        caseList.value.forEach(item => {
            item.show = true;
        });
        casePageSize.value = res.data.per_page;
        caseTotal.value = res.data.total;
    } catch (error) {
        console.error(error);
    }
}
</script>
<style lang="less" scoped>
.detail_list {
    padding: 30px 0;
}

.detail_box {
    position: relative;

    &~& {
        margin-top: 100px;
    }


    .pic_box {
        max-height: calc((100vw / 1920) * 540);
        overflow: hidden;
        transition: all ease 0.5s;

        &.show {
            max-height: 3000px;
        }
    }

    .intro {
        word-break: break-all;
        padding-bottom: 100px;
        width: calc(100vw / 1920 * 1100);
    }

    .detail {
        display: block;
        width: calc((100% - 30px) / 4);
        height: calc((100vw / 1920) * 530);
        margin-top: 10px;
        border-radius: 8.22px;
        margin-right: 10px;
        object-fit: cover;

        &:nth-of-type(4n) {
            margin-right: 0;
        }
    }


    .show_more {
        width: 60px;
        height: 50px;
        border-radius: 33px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -25px;
        cursor: pointer;
        z-index: 3;
        transition: all ease .5s;
        background: rgba(51, 51, 51, 1);
        box-shadow: 0px 18px 20px 0px rgba(0, 0, 0, 0.08);
        background-image: url('../assets/static/icon/show_more.png');
        background-size: 24px;
        background-position: center;
        background-repeat: no-repeat;

        &.active {
            transform: rotate(180deg);
            box-shadow: 0px -18px 20px 0px rgba(0, 0, 0, 0.08);
        }
    }
}

.img_viewer {
    .actions {
        width: 19px;
        height: 28px;
        display: block;
        margin: 0 50px;
        cursor: pointer;
    }

    .swiper {
        width: 800px;
        height: 100vh;

        .swiper-slide {
            height: 100vh;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
        }
    }
}


@media only screen and (max-width: 980px) {
    .detail_list {
        padding: 30px 0 90px;
        margin-bottom: 0;

        .intro {
            width: 100%;
            font-size: 9px;
            padding-bottom: 15px;
            color: rgba(51, 51, 51, 0.5);
        }
    }

    .detail_box {
        .detail {
            width: calc((100% - 15px) / 4);
            height: calc((100vw / 750) * 220);
            margin-top: 5px;
            margin-right: 5px;
            border-radius: 4px;
        }

        .fs34 {
            font-size: 28px;
        }

        .pic_box {
            max-height: calc((100vw / 750) * 235);
            overflow: hidden;
            transition: all ease 0.5s;

            &.show {
                max-height: calc((100vw / 750) * 2000);
            }
        }


        .show_more {
            width: 36px;
            height: 30px;
            bottom: -15px;
            background-size: 14.4px;

            &.active {
                transform: rotate(180deg);
                box-shadow: 0px -18px 20px 0px rgba(0, 0, 0, 0.08);
            }
        }
    }

    .dialog_body {
        width: 100vw;
    }

    .img_viewer {
        width: 100vw;

        .actions {
            margin: 0;
            position: absolute;
            top: 105%;
            left: 40%;
            transform: translateX(-100%);
            z-index: 10;

            &:nth-of-type(2) {
                right: 40%;
                transform: translateX(100%);
                left: auto;
            }
        }

        .swiper {
            width: 100vw;
            height: 500px;

            .swiper-slide {
                height: 500px;
            }

            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
                position: relative;
                z-index: 9;
            }
        }
    }
}</style>