<template>
  <Header :isIndex="isIndex"></Header>
  <div class="container">

    <!-- <swiper class="swiper" :pagination="false" :autoplay="autoplayOptions" @swiper="onSwiper" @slideChange="onSlideChange"
      :modules="modules" loop>
      <swiper-slide v-for="(item, index) in bannerList" :key="index">
        <div class="swiper_item flex-box flex-col flex-center colf"
          :style="{ backgroundImage: 'url(' + require('../assets/static/images/banner.png') + ')' }">
          <div class="fs15">SIGNEO PAINT</div>
          <div class="fs64">德国诗珑</div>
          <div class="fs20">德国原装原罐进口 · LGA卫生制造标准</div>
        </div>
      </swiper-slide>
    </swiper> -->
    <div class="" :class="banner.image_type == 'video' ? 'video_box' : 'img_box'">
      <video :src="banner.image" v-if="banner.image_type == 'video'" loop @loadeddata="onLoadedData" ref="videoRef" muted></video>
      <img :src="banner.image" v-if="banner.image_type == 'image'" alt="">
      <!-- <div class="item flex-box flex-col flex-center colf">
        <div class="fs15">SIGNEO PAINT</div>
        <div class="fs64">德国诗珑</div>
        <div class="fs20">德国原装原罐进口 · LGA卫生制造标准</div>
      </div> -->
    </div>
    <div class="m1920 need bgf9 flex-box flex-col flex-center">
      <!-- <div class="fs15 col17 pb20 ">Color service</div>
      <div class="flex-box fs48">
        <img src="../assets/static/icon/icon_color_service.png" class="icon_color_service" alt="">
        <div class="pl10 fwb">你可能需要的<span class="service">色彩服务</span></div>
      </div> -->
      <img :src="title_images[0].image" class="title_pic" alt="" v-if="title_images[0]">
    </div>
    <div class="m1920 flex-box flex-warp color_service">
      <router-link :to="{ name: 'card' }" class="service_item">
        <img :src="color_card" alt="">
      </router-link>
      <router-link :to="{ name: 'trends' }" class="service_item">
        <img :src="color_trend" alt="">
      </router-link>
    </div>
    <div class="m1920 flex-box flex-warp color_service">
      <router-link :to="{ name: 'guide' }" class="service_item">
        <img :src="style_guide" alt="">
      </router-link>
      <router-link :to="{ name: 'match' }" class="service_item">
        <img :src="color_scheme" alt="">
      </router-link>
    </div>

    <div class="m1920 need bgf9 flex-box flex-col flex-center">
      <!-- <div class="fs15 col17 pb20">Product service</div>
      <div class="flex-box fs48">
        <img src="../assets/static/icon/icon_product.png" class="icon_product" alt="">
        <div class="pl10 fwb">你想要了解的<span class="product">产品服务</span></div>
      </div> -->
      <img :src="title_images[1].image" class="title_pic" alt="" v-if="title_images[1]">
    </div>
    <router-link :to="{ name: 'series' }">
      <div class="m1920 product_service">
        <div class="detail_box col3">
          <div class="fs12 fwb">Product System</div>
          <div class="title fwb">产品体系</div>
          <div class="detail">{{ products.desc }}</div>
          <div class="flex-box flex-center to_detail">查看详情</div>
        </div>
        <img :src="products.image" class="bg" alt="">
      </div>
    </router-link>
    <router-link :to="{ name: 'illustrate' }">
      <div class="m1920 product_service flex-box flex-end">
        <div class="detail_box col3">
          <div class="fs12 fwb">Construction Description</div>
          <div class="title fwb">施工说明</div>
          <div class="detail">{{ constructions.desc }}</div>
          <div class="flex-box flex-center to_detail">查看详情</div>
        </div>
        <img :src="constructions.image" class="bg" alt="">
      </div>
    </router-link>

    <div class="m1920 need bgf9 flex-box flex-col flex-center">
      <!-- <div class="fs15 col17 pb20 ">Product case</div>
      <div class="flex-box fs48">
        <img src="../assets/static/icon/icon_case.png" class="icon_case" alt="">
        <div class="pl10 fwb">你可能想参考的<span class="product">诗珑案例</span></div>
      </div> -->
      <img :src="title_images[4].image" class="title_pic" alt="" v-if="title_images[4]">
    </div>

    <div class="m1920 case colf flex-box flex-col flex-center" :style="{ backgroundImage: 'url(' + cases.image + ')' }">
      <div class="title pb30">查阅诗珑涂料案例</div>
      <div class="detail" v-html="`${cases.desc}`.replace(/\n/g, '<br/>')"></div>
      <router-link to="/casePage" class="to fs18 col3">了解更多</router-link>
    </div>

    <div class="m1920 need bgf9 flex-box flex-col flex-center">
      <!-- <div class="fs15 col17 pb20 ">Tools</div>
      <div class="flex-box fs48">
        <img src="../assets/static/icon/icon_tool.png" class="icon_tool" alt="">
        <div class="pl10 fwb">你可能需要用到的<span class="tool">实用工具</span></div>
      </div> -->
      <img :src="title_images[2].image" class="title_pic" alt="" v-if="title_images[2]">
    </div>

    <div class="flex-box flex-center tool_list">
      <router-link :to="{ name: 'calculate' }" class="tool_item">
        <div class="top flex-box flex-center"><img src="../assets/static/icon/icon_tool1_1.png" class="icon_tool1_1"
            alt=""></div>
        <div class="fs20 fwb">漆量计算</div>
        <div class="fs20 fwb">控制漆量 安心省钱</div>
        <div class="to">了解详情 >></div>
      </router-link>
      <!-- <router-link :to="{ name: 'shop' }" class="tool_item">
        <div class="top flex-box flex-center"><img src="../assets/static/icon/icon_tool2_1.png" class="icon_tool2_1"
            alt=""></div>
        <div class="fs20 fwb">门店查询</div>
        <div class="fs20 fwb">线下体验 一目了然</div>
        <div class="to">了解详情 >></div>
      </router-link> -->
      <router-link :to="{ name: 'security' }" class="tool_item">
        <div class="top flex-box flex-center"><img src="../assets/static/icon/icon_tool3_1.png" class="icon_tool3_1"
            alt=""></div>
        <div class="fs20 fwb">防伪自查</div>
        <div class="fs20 fwb">一站式服务 省时保险</div>
        <div class="to">了解详情 >></div>
      </router-link>
    </div>

    <div class="m1920 need bgf9 flex-box flex-col flex-center">
      <!-- <div class="fs15 col17 pb20 ">Signeo</div>
      <div class="flex-box fs48">
        <img src="../assets/static/icon/icon_trust.png" class="icon_trust" alt="">
        <div class="pl10 fwb">值得消费者信任的<span class="trust">百年诗珑</span></div>
      </div> -->
      <img :src="title_images[3].image" class="title_pic" alt="" v-if="title_images[3]">
    </div>
    <template v-if="construction">
      <a :href="item.url" target="_blank" v-for="(item, index) in construction" :key="index" class="history_a">
        <div class="m1920 flex-box flex-col-start history_item ">
          <img :src="item.image" class="history" alt="">
          <div class="right flex-grow-1">
            <div class="fs30 fwb title m-ellipsis">{{ item.name }}</div>
            <div class="detail m-ellipsis-l3">{{ item.detail }}</div>
            <div class="flex-box flex-center to_detail cursor">查看详情</div>
          </div>
        </div>
      </a>
    </template>
  </div>
  <Footer></Footer>
</template>
<script setup>
import { ref, reactive, inject, onMounted, onUnmounted } from "vue";
const api = inject('api'); // 使用inject来注入接口
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
  title: "诗珑",
  meta: [
    {
      name: "description",
      content: "诗珑",
    },
    {
      name: "keywords",
      content: "诗珑",
    },
  ],
});
// // 引入swiper
// import { Pagination, A11y, Autoplay } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/vue";
// import "swiper/swiper.min.css";
// import "swiper/swiper-bundle.min.css";
// const modules = [Pagination, A11y, Autoplay];
// //自动轮播的配置
// const autoplayOptions = {
//   delay: 5000,
// }
// // 设置banner列表
// let bannerList = ref(["", ""]);

// 设置首页的头部样式
let isIndex = ref(true);
const handleScroll = () => {
  // 输出当前页面滚动的距离
  if (window.scrollY) {
    isIndex.value = false;
  } else {
    isIndex.value = true;
  }
};
// 页面加载 这里相当于onload
onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  getIndex();
});
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
let banner = ref({});
let construction = ref([]);
let color_card = ref('');
let color_scheme = ref('');
let color_trend = ref('');
let style_guide = ref('');
let cases = ref({});
let products = ref({});
let constructions = ref({});
let title_images = ref([]);
const getIndex = async () => {
  try {
    const res = await api.getBanner(); // 调用接口
    banner.value = res.data.banner;
    construction.value = res.data.construction;
    color_card.value = res.data.color_card;
    color_scheme.value = res.data.color_scheme;
    color_trend.value = res.data.color_trend;
    style_guide.value = res.data.style_guide;
    cases.value = res.data.case;
    products.value = res.data.products;
    constructions.value = res.data.constructions;
    title_images.value = res.data.title_images;
  } catch (error) {
    console.error(error);
  }
}

const videoRef = ref(null);

const onLoadedData = () => {
  // 视频加载完成后自动播放
  if (videoRef.value) {
    videoRef.value.play().catch((error) => {
      console.error('自动播放失败:', error);
    });
  }
}
</script>
<style lang="less" scoped>
.header~.container {
  padding-top: 0;
}

.swiper {
  width: 100vw;
  height: 100vh;

  .swiper_item {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // object-fit: cover;

    .fs64 {
      font-size: 64px;
      padding: 38px 0 31px;
      line-height: 1;
      font-weight: bold;
      letter-spacing: 8px;
    }
  }
}

.video_box {
  width: 100vw;
  height: 100vh;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  video {
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 1;
    object-fit: cover;
  }

  .item {
    width: 100vw;
    height: 100vh;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }

  .fs64 {
    font-size: 64px;
    padding: 38px 0 31px;
    line-height: 1;
    font-weight: bold;
    letter-spacing: 8px;
  }
}

.need {
  padding: 180px 0;

  .title_pic {
    height: 107px;
    width: auto;
    display: block;
  }

  .pl10 {
    letter-spacing: 4px;

    span {
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }

    span.service {
      background-image: linear-gradient(to right, #FF3131 0%, #FF3ABC 50%, #0066FF 100%);
    }

    span.product {
      background-image: linear-gradient(90.04deg, #0C24FF 59.56%, #D99AFF 98.96%);
    }

    span.tool {
      background-image: linear-gradient(to right, #FF0000 0%, #6821FF 100%);
    }

    span.trust {
      background-image: linear-gradient(to right, #876100 0%, #ECC600 100%);
    }
  }

  .icon_color_service {
    width: 37px;
    height: 37px;
    display: block;
  }

  .icon_product {
    width: 35px;
    height: 34px;
    display: block;
  }

  .icon_case {
    width: 34px;
    height: 34.5px;
    display: block;
  }

  .icon_tool {
    width: 39px;
    height: 40px;
    display: block;
  }

  .icon_trust {
    width: 40px;
    height: 40px;
    display: block;
  }
}

.color_service {
  .service_item {
    width: 50%;
    display: block;
    overflow: hidden;

    img {
      display: block;
      transition: all ease .5s;

    }
  }
}

.product_service {
  height: 564px;
  position: relative;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  &~& {
    margin-top: -1px;
  }

  .detail_box {
    width: 594px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    padding: 40px 140px 0 40px;
    position: relative;
    z-index: 2;

    .title {
      padding-top: 48px;
      font-size: 44px;
      padding-bottom: 18px;
      line-height: 1;
    }

    .detail {
      line-height: 1.6;
      text-align: justify;
    }

    .to_detail {
      border: 1px solid #000;
      width: 120px;
      height: 36px;
      border-radius: 100px;
      margin-top: 75px;
      font-size: 10px;

      &::after {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        background: url('../assets/static/icon/icon_to.png') no-repeat center;
        background-size: 100%;
        margin-left: 3px;
      }
    }
  }
}

.case {
  height: 1012px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .title {
    font-size: 60px;
    letter-spacing: 10px;
    font-weight: bold;
  }

  .detail {
    font-size: 12px;
    padding-bottom: 5px;
    text-align: center;
  }

  .to {
    width: 216px;
    height: 56px;
    text-align: center;
    line-height: 56px;
    background: #fff;
    border-radius: 100px;
    margin-top: 64px;
    transition: all ease .5s;

    &:hover {
      background: #000;
      color: #fff;
    }
  }
}

.tool_item {
  width: 268px;
  height: 318px;
  border-radius: 18px;
  background: #fff;
  margin: 0 16px;
  position: relative;
  padding: 26px 0 0 26px;
  cursor: pointer;
  transition: all ease 1s;

  &:hover {
    transform: scale(1.2);

  }

  .to {
    width: 82px;
    height: 24px;
    border: 1px solid #808080;
    border-radius: 100px;
    color: #999;
    font-size: 10px;
    display: block;
    text-align: center;
    line-height: 24px;
    margin-top: 10px;
    position: relative;
    z-index: 2;
  }

  .top {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    margin: auto;
    background: url("../assets/static/icon/icon_tool_bg.png") no-repeat center;
    background-size: 100%;

    .icon_tool1_1 {
      width: 29.5px;
      height: 26.5px;
      display: block;
      margin-top: -6px;
    }

    .icon_tool2_1 {
      width: 30px;
      height: 30px;
      display: block;
      margin-top: -6px;
    }

    .icon_tool3_1 {
      width: 34px;
      height: 34px;
      display: block;
      margin-top: -8px;
    }
  }

  &:nth-of-type(1) {
    background: linear-gradient(#fff 0%, #fff 46%, #ECFFCD 100%, );

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 227px;
      background: url("../assets/static/icon/icon_tool1.png") no-repeat center;
      background-size: 100% 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
    }
  }

  &:nth-of-type(2)::after {
    content: "";
    display: block;
    width: 100%;
    height: 318px;
    background: url("../assets/static/icon/icon_tool2.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &:nth-of-type(3)::after {
    content: "";
    display: block;
    width: 100%;
    height: 318px;
    background: url("../assets/static/icon/icon_tool3.png") no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.history_a {
  display: block;
}

.history_a~.history_a {
  margin-top: 25px;
}

.history_item {
  background: #fff;
  height: 470px;

  .history {
    width: 760px;
    height: 470px;
    display: block;
    object-fit: cover;
  }

  .right {
    padding: 25px 45px 0;

    .title {
      height: 52px;
      line-height: 52px;
      margin-bottom: 6px;
    }

    .detail {
      font-size: 13px;
      margin-bottom: 6px;
      line-height: 23px;
      height: 69px;
    }

    .to_detail {
      border: 1px solid #000;
      width: 120px;
      height: 36px;
      font-size: 10px;
      border-radius: 100px;
      margin-top: 75px;

      &::after {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        background: url('../assets/static/icon/icon_to.png') no-repeat center;
        background-size: 100%;
        margin-left: 3px;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .history_item {
    .history {
      width: 50%;
      height: 470px;
      display: block;
    }

    .right {
      padding-right: 45px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .history_item {
    display: block;
    height: auto;
    margin: 0;

    .history {
      width: 100%;
      height: 200px;
      display: block;
    }

    .right {
      padding: 10px 26px 28px;
      width: 100%;

      .title {
        font-size: 16px;
        height: 28px;
        line-height: 28px;
        margin-bottom: 0;
      }

      .detail {
        font-size: 8px;
        height: 48px;
        line-height: 16px;
        margin-bottom: 0;

      }
    }

    .to_detail {
      display: none;
    }
  }

  .fs15.col17.pb20 {
    font-size: 9px;
  }

  .need {
    text-align: center;
    padding: 50px 0;

    .title_pic {
      width: 90%;
      height: auto;
    }

    .fs48 {
      font-size: 24px;
    }
  }

  .color_service,
  .product_service,
  .case {
    margin: 0;
  }

  .color_service {
    display: block;

    a.service_item {
      width: 100%;
      display: block;
    }
  }

  .product_service {
    display: block;
    height: auto;
    background: #fff;

    .bg {
      position: relative;
      margin-top: 25px;
    }

    .detail_box {
      width: auto;
      padding: 20px 15px 0 15px;
      height: 70%;

      .fs12.fwb {
        font-size: 9px;
        height: 25px;
      }

      .title {
        font-size: 22px;
        padding: 9px 0;
      }

      .detail {
        font-size: 10px;
        color: rgba(51, 51, 51, 0.5);
      }
    }

    .to_detail {
      display: none;
    }
  }

  .case {
    height: 616px;

    .title {
      text-align: center;
      font-size: 30px;
      padding: 0 50px 30px;
      letter-spacing: 4px;
    }

    .detail {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 9px;
    }

    .to {
      width: 108px;
      height: 32px;
      font-size: 10px;
      line-height: 32px;
    }
  }

  .tool_list {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 15px;

    .tool_item {
      display: block;
      width: calc((100vw - 45px)/2);
      margin-right: 15px;
      margin-left: 0;
      margin-bottom: 15px;
      padding: 15px 11px 0;
      height: 180px;
      overflow: hidden;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-of-type(1)::after,
      &:nth-of-type(2)::after,
      &:nth-of-type(3)::after {
        height: 100%;
        background-size: 100% auto;
      }

      .top,
      .to {
        display: none;
      }

      .fs20 {
        font-size: 11px;
      }
    }
  }
  .video_box,
  .video_box video{
    height: auto;
  }
}
</style>