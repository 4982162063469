<template>
  <div class="footer flex-box flex-col-start m1620">
    <div class="flex-box flex-col-start flex-wrap links flex-grow-1">
      <div class="flex-box flex-col-start menus">
        <div class="item">
          <div class="fs16 fwb pb10 cursor">色彩服务</div>
          <router-link class="link fs14" :to="{ name: 'trends' }">色彩趋势</router-link>
          <router-link class="link fs14" :to="{ name: 'card' }">色卡查询</router-link>
          <router-link class="link fs14" :to="{ name: 'match' }">色系搭配</router-link>
          <router-link class="link fs14" :to="{ name: 'guide' }">风格指南</router-link>
        </div>
        <div class="item">
          <div class="fs16 fwb pb10 cursor">产品服务</div>
          <router-link class="link fs14" :to="{ name: 'series' }">产品体系</router-link>
          <router-link class="link fs14" :to="{ name: 'illustrate' }">施工说明</router-link>
          <router-link class="link fs14" :to="{ name: 'formula' }">调色配方</router-link>
        </div>
        <div class="item">
          <div class="fs16 fwb pb10 cursor">实用工具</div>
          <router-link class="link fs14" :to="{ name: 'calculate' }">漆量计算</router-link>
          <!-- <router-link class="link fs14" :to="{ name: 'shop' }">门店查询</router-link> -->
          <router-link class="link fs14" :to="{ name: 'security' }">防伪自查</router-link>
        </div>
        <div class="item">
          <div class="fs16 fwb pb10 cursor">关于我们</div>
          <router-link class="link fs14" :to="{ name: 'story' }">品牌故事</router-link>
          <router-link class="link fs14" :to="{ name: 'authentication' }">环保认证</router-link>
          <router-link class="link fs14" :to="{ name: 'contact' }">联系我们</router-link>
          <router-link class="link fs14" :to="{ name: 'cooperation' }">博主合作</router-link>
        </div>
      </div>
      <div class="flex-box flex-col-start contact">
        <div class="item">
          <div class="fs16 fwb pb10">{{ footInfo.company_name }}地址</div>
          <div class="fs14 address">{{ footInfo.company_address }}</div>
        </div>
        <div class="item">
          <div class="fs16 fwb pb10">联系方式</div>
          <div class="fs14 address add">{{ footInfo.company_phone }}</div>
          <div class="fs14 address add">{{ footInfo.company_email }}</div>
          <!-- <div class="fs16 fwb ptb10">邮编</div>
          <div class="fs14 address">{{ footInfo.postcode }}</div> -->
        </div>
        <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank" v-if="footInfo.beian != ''">
          <div class="item">
            <div class="fs16 fwb pb10 col0">备案号</div>
            <div class="fs14 address">{{ footInfo.beian }}</div>
          </div>
        </a>
      </div>
    </div>
    <div class="flex-box codes">
      <div class="items" v-for="(item, index) in footInfo.app_images" :key="index">
        <img :src="item.image" class="icon_coop" alt="">
        <img :src="item.account_image" class="code" alt="">
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口

// 页面加载 这里相当于onload
onMounted(() => {
  getFootInfo();
});
let footInfo = ref({});
const getFootInfo = async () => {
  try {
    const info = localStorage.getItem('footInfo');
    if (info) {
      footInfo.value = JSON.parse(info);
      return;
    }
    const res = await api.getFooter(); // 调用接口
    localStorage.setItem('footInfo', JSON.stringify(res.data));
    footInfo.value = res.data;
  } catch (error) {
    console.error(error);
  }
}
</script>
<style lang="less" scoped>
.footer {
  padding-top: 160px;
  padding-bottom: 160px;
  background: #F9F9F9;

  .links {
    display: flex;
  }
}

.item {
  padding-right: 70px;

  .link {
    line-height: 2;
    display: block;
    color: rgba(51, 51, 51, 0.8);
  }

  .address {
    line-height: 2;
    height: 56px;
    width: 160px;
    color: rgba(51, 51, 51, 0.8);

    &.add {
      height: 30px;
    }
  }

  &:nth-of-type(6) {
    padding-right: 0;
  }
}

.menus {
  margin-bottom: 15px;
}

.contact {
  .item {
    padding-right: 30px;
  }
}

.icon_coop {
  width: 50px;
  height: auto;
  display: block;
  margin: 0 auto;
}


.codes {
  .items {
    width: 90px;
    position: relative;

    &:hover {
      .code {
        opacity: 1;
      }
    }

    .code {
      transition: all ease .5s;
      opacity: 0;
      width: 90px;
      height: 90px;
      position: absolute;
      top: 35px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

}

@media only screen and (max-width: 980px) {
  .footer {
    display: block;
    padding: 100px 0;

    .item {
      padding-right: 0;
    }

    .links {
      width: 100%;
    }

    .menus,
    .contact {
      width: calc(100vw - 30px);
      padding: 0 15px;
      justify-content: space-between;
    }

    .contact {
      display: block;
    }

    .contact {
      padding-top: 20px;

      .item {
        text-align: center;

        .address {
          height: 30px;
          margin-bottom: 20px;
          word-break: break-all;
          width: 100%;

          &.add {
            margin-bottom: 0;
          }
          &.add:nth-of-type(3){
            margin-bottom: 20px;
          }
        }
      }
    }

    .codes {
      justify-content: space-around;
      flex-wrap: wrap;

      .items {
        .code {
          position: relative;
          opacity: 1;
          top: 5px;
        }
      }
    }
  }
}
</style>