<template>
    <Header></Header>
    <div class="container">
        <div class="page_title m1620 pl0">
            <div class="fs15">ABOUT US</div>
            <div class="fs64 fwb">关于我们</div>
        </div>
        <div class="flex-box navigator_box m1620 pl0">
            <router-link :to="{ name: 'story' }" class="item ">品牌故事</router-link>
            <router-link :to="{ name: 'authentication' }" class="item ">环保认证</router-link>
            <router-link :to="{ name: 'contact' }" class="item active">联系我们</router-link>
            <router-link :to="{ name: 'cooperation' }" class="item">博主合作</router-link>
        </div>

        <div class="m1620">
            <div class="contact_box ">
                <div class="fs48 pb25 fwb tc title">{{ contactInfo.company_name }}</div>
                <div class="detail">{{ contactInfo.company_desc }}</div>
                <div class="flex-box tc">
                    <div class="flex-grow-1">
                        <img src="../../assets/static/icon/icon_contact2.png" class="icon_contact" alt="">
                        <div class="fs14 ptb5 fwb">联系电话</div>
                        <div class="fs14">{{ contactInfo.company_phone }}</div>
                    </div>
                    <div class="flex-grow-1">
                        <img src="../../assets/static/icon/icon_contact1.png" class="icon_contact" alt="">
                        <div class="fs14 ptb5 fwb">{{ contactInfo.company_name }}地址</div>
                        <div class="fs14">{{ contactInfo.company_address }}</div>
                    </div>
                    <div class="flex-grow-1">
                        <img src="../../assets/static/icon/icon_contact3.png" class="icon_contact" alt="">
                        <div class="fs14 ptb5 fwb">客服邮箱</div>
                        <div class="fs14">{{ contactInfo.company_email }}</div>
                    </div>
                </div>
                <!-- <div class="platform">
                    <div class="platform_box flex-box flex-center" v-for="(item, index) in contactInfo.app_images"
                        :key="index">
                        <img :src="item.image" class="icon_platform" alt="">
                        <img :src="item.account_image" class="code" alt="">
                    </div>
                </div> -->
                <div class="code_box flex-box flex-center tc flex-wrap fs14">
                    <div class="code_item" v-for="(item, index) in contactInfo.app_images" :key="index">
                        <div class="code"><img :src="item.account_image" alt=""></div>
                        <div>{{ item.name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute()
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "联系我们_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});
// 接收传递过来的下标
onMounted(() => {
    contactUs();
})
onBeforeRouteUpdate((to) => {
    contactUs();
});

// 关于我们
let contactInfo = ref({});
const contactUs = async () => {
    try {
        const res = await api.getContactUs(); // 调用接口
        contactInfo.value = res.data;
    } catch (error) {
        console.error(error);
    }
}
</script>
<style lang="less" scoped>
.contact_box {
    padding: 120px 0 0;
    width: 920px;
    margin: 0 auto;

    .title {
        color: #373434;
    }

    .detail {
        color: rgba(51, 51, 51, 0.8);
        font-size: 14px;
        line-height: 1.5;
        text-align: left;
        margin-bottom: 100px;
    }

    .icon_contact {
        width: 25px;
        height: 25px;
        display: block;
        margin: 15px auto 5px;
    }

    .code_box {
        margin-top: 180px;
    }

    .code_item {
        width: calc(100% / 3);
        margin-bottom: 34px;

        .code {
            width: 94px;
            height: 94px;
            margin: 0 auto 16px;
            box-shadow: 0px 2.55px 2.55px 0px rgba(0, 0, 0, 0.05);
            border-radius: 5.75px;
            overflow: hidden;

            img {
                width: 94px;
                height: 94px;
            }
        }
    }
}

@media only screen and (max-width: 980px) {
    .contact_box {
        display: block;
        padding: 0;
        width: 100%;
        .fs48 {
            font-size: 30px;
        }

        .flex-grow-1 {
            width: auto;
            padding-bottom: 50px;
            text-align: center;
        }
        .detail + .flex-box{
            display: block;
        }
        .code_box {
            margin-top: 50px;
        }
    }
}</style>