<template>
    <Header></Header>
    <div class="container">
        <div class="page_title m1620">
            <div class="fs15">SIGNEO TOOLS</div>
            <div class="fs64 fwb">诗珑工具</div>
        </div>
        <div class="flex-box navigator_box m1620">
            <div class="flex-box">
                <router-link :to="{ name: 'calculate' }" class="item ">漆量计算</router-link>
                <!-- <router-link :to="{ name: 'shop' }" class="item ">门店查询</router-link> -->
                <router-link :to="{ name: 'security' }" class="item active">防伪自查</router-link>
            </div>
        </div>
        <div class="m1620">
            <div class="text_box">
                <a :href="security.security_url" target="_blank" class="jump">跳转至GS1官网</a>
                <!-- <img :src="security.security_image" alt=""> -->
                <div v-html="security.security_content"></div>

            </div>
        </div>
    </div>
    <Footer></Footer>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute()
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "防伪自查_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});
// 接收传递过来的下标
onMounted(() => {
    securityImage();
})
onBeforeRouteUpdate((to) => {
    securityImage();
});
// 防伪自查
let security = ref('');
const securityImage = async () => {
    try {
        const res = await api.getsecurityImage(); // 调用接口
        security.value = res.data;
    } catch (error) {
        console.error(error);
    }
}
</script>
<style lang="less" scoped>
.text_box {
    padding: 60px 0 0;

    img {
        display: block;
        margin: 0 auto;
    }

    .jump {
        width: 213px;
        height: 67px;
        border-radius: 100px;
        display: block;
        background: rgba(51, 51, 51, 1);
        text-align: center;
        line-height: 67px;
        font-size: 18px;
        color: #fff;
        font-weight: 400;
        margin-left: calc((100vw / 1920) * 87);
    }
}

@media only screen and (max-width: 980px) {
    .text_box {
        padding: 0;

        .jump {
            width: 106px;
            height: 33px;
            font-size: 12px;
            line-height: 33px;
        }
    }


}
</style>