<template>
  <div class="pagination">
    <a href="javascript:;" @click="pageTurn(0)" class="page first_last" v-if="paging.index > 0">
      <!-- <img src="/assets/icon/last.png" class="img_last rotate" alt=""> -->
      <span style="padding:0 5px;">第一页</span>
    </a>
    <a href="javascript:;" @click="pageTurn(paging.index - 1)" class="page last" v-if="paging.index > 0">
      <span></span>
    </a>
    <template v-for="i in sum">
      <a href="javascript:;" @click="pageTurn(i - 1)" :key="i" v-if="showText(i)" v-text="showText(i)" class="page"
        :class="i === paging.index + 1 ? 'active' : ''">
      </a>
    </template>
    <a href="javascript:;" @click="pageTurn(paging.index + 1)" class="page next" v-if="paging.index < sum - 1">
      <span></span>
    </a>
    <a href="javascript:;" @click="pageTurn(sum - 1)" class="page first_last" v-if="paging.index < sum - 1">
      <!-- <img src="/assets/icon/last.png" class="img_last" alt=""> -->
      <span style="padding:0 5px;">尾页</span>
    </a>
  </div>
</template>
<script>
export default {
  props: ["sumCount", "perCount", "page"],
  data() {
    return {
      paging: {
        index: 0, // index记录当前页码
        count: 9, // count表示每页多少条记录
      },
    };
  },
  watch: {
    page(val) {
      this.paging.index = val;
    }
  },
  computed: {
    sum() {
      // sum表示共有多少页
      return Math.ceil(this.sumCount / this.perCount);
    },
  },
  methods: {
    // 页码显示（有省略号）
    showText(i) {
      const vm = this;
      const index = vm.paging.index;
      const sum = vm.sum;
      if (i < 3 || i > sum - 2) {
        // 前两个和最后两个始终显示
        return i;
      } else if (i <= index + 2 && i >= index) {
        // 当前页的前一页和后一页始终显示
        return i;
      } else if (i === index + 3 || i === index - 1) {
        // 当前页的前前页和后后页显示 ...
        return "...";
      } // 除此之外都不显示
      return false;
    },
    // 翻页
    pageTurn(i) {
      const vm = this;
      if (i < 0 || i >= vm.sum || i === vm.paging.index) {
        return;
      }
      vm.paging.index = i;
      // 告诉父组件翻页了，让父组件自己调用init
      vm.$emit("pageTurn", i, vm.perCount);
    },
  },
};
</script>
<style lang="less" scoped>
.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
}

.pagination .page {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #333;
  margin: 0 10px;
  font-size: 17px;
  background: url("../assets/static/icon/page.png") no-repeat center;
  background-size: 98%;
  position: relative;
  transition: all ease .2s;
}

.pagination .page.active {
  text-align: center;
  color: #fff;
  margin: 0 10px;
  background-image: url("../assets/static/icon/page-a.png");
}

.pagination .page:hover,
.pagination .page.active {
  color: #fff;
  background-image: url("../assets/static/icon/page-a.png");
}

.pagination .page.next span,
.pagination .page.last span {
  display: block;
  width: 10px;
  height: 14px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-image: url("../assets/static/icon/last.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 14px;
}

.pagination .page.next span {
  background-image: url("../assets/static/icon/next.png");
}

.pagination .page.next:hover span {
  background-image: url("../assets/static/icon/next-w.png");
}

.pagination .page.last:hover span {
  background-image: url("../assets/static/icon/last-w.png");
}

.pagination .page.first_last {
  width: 75px;
  background-image: url("../assets/static/icon/pagel.png");
}

.pagination .page.first_last:hover {
  background-image: url("../assets/static/icon/pagel-a.png");
}

@media only screen and (max-width: 980px) {
  .pagination {
    flex-wrap: wrap;
    height: 100px;
  }

  .pagination .page {
    font-size: 12px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .pagination .page.first_last {
    width: 56.25px;
  }

  .pagination .page,
  .pagination .active {
    margin: 0 5px 5px;
  }
}</style>
