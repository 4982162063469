<template>
    <Header></Header>
    <div class="container">
        <div class="m1920 detail_box flex-box flex-col flex-center">
            <div class="goods_info flex-box">
                <div class="goods_box flex-box relative">
                    <img src="../../assets/static/icon/icon_back2.png" class="icon_back" alt="" @click="$router.back">
                    <img :src="goodsInfo.image" class="pic" alt="">
                    <div class="info">
                        <div class="pb10">
                            <span class="series_name fs16 fwb pr20">SIGNEO</span>
                            <span class="series_name fs16" v-if="goodsInfo.series">{{
                                toUpperCase(goodsInfo.series.series_name_en) }}</span>
                        </div>
                        <div class="fs25 col6 fwb relative series flex-box flex-col-start" v-if="goodsInfo.series">
                            <div>{{ goodsInfo.series.series_name_cn }}</div>
                            <span class="size">{{ goodsInfo.capacity ? goodsInfo.capacity : goodsInfo.series.series_capacity
                            }}</span>
                        </div>
                        <div class="title">{{ goodsInfo.goods_name }}</div>
                        <div class="flex-box features">
                            <img :src="item.icon" v-for="(item, index) in goodsInfo.tag_arr_images" :key="index">
                        </div>
                        <div class="flex-box targets">
                            <div class="item" v-for="(item, index) in goodsInfo.tag_arr_text" :key="index">{{
                                item.label_name }}
                            </div>
                        </div>
                        <div class="flex-box flex-wrap buy_box">
                            <div class="flex-box">
                                <a :href="goodsInfo.tao_url" v-if="goodsInfo.tao_url !== ''" target="_blank"
                                    class="buy flex-box flex-center tao">淘宝购买</a>
                                <a :href="goodsInfo.jd_url" v-if="goodsInfo.jd_url !== ''" target="_blank"
                                    class="buy flex-box flex-center jd">京东购买</a>
                            </div>
                            <div class="flex-box flex-center progress relative">打开微信小程序 领取更多优惠
                                <div class="code_box"><img :src="goodsInfo.mini_url" alt=""></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-html="goodsInfo.pc_content"></div>
        </div>
    </div>
    <Footer></Footer>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute()
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "产品详情_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});


// 接收传递过来的商品id
onMounted(() => {
    getGoodsDetail(route.query.id);
})
onBeforeRouteUpdate((to) => {
    getGoodsDetail(to.query.id);
});
let goodsInfo = ref({});
const getGoodsDetail = async (id) => {
    try {
        const res = await api.getGoodsDetail({ id });
        goodsInfo.value = res.data;
    } catch (error) {
        console.error(error);
    }
}
const toUpperCase = (text) => {
    return text.toUpperCase();
}

</script>
<style lang="less" scoped>
.container {
    .links {
        padding-top: 100px;
    }

    .detail_box {
        padding-bottom: 100px;

    }

    .goods_info {
        min-height: calc(100vh - 80px);
    }


    .goods_box {

        .icon_back {
            width: 48px;
            height: 48px;
            display: block;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: -150px;
            bottom: 0;
            margin: auto;
        }

        .series_name {
            color: rgba(51, 51, 51, .45);
        }

        .series {
            color: rgba(51, 51, 51, .8);
        }

        .pic {
            margin-right: 40px;
            width: 384px;
            height: auto;
            display: block;
        }

        .size {
            height: 14px;
            border: 1px solid rgba(51, 51, 51, .8);
            color: rgba(51, 51, 51, .8);
            font-size: 10px;
            text-align: center;
            line-height: 12px;
            border-radius: 100px;
            padding: 0 6px;
            display: inline-block;
            margin-left: 5px;
        }

        .title {
            font-size: 52px;
            color: #333;
            font-weight: bold;
        }

        .features {
            img {
                width: 100px;
                height: auto;
                // margin-right: 10px;
                display: block;

                &:nth-of-type(1) {
                    margin-left: -10px;
                }
            }
        }

        .targets {
            padding: 12px 0 24px;

            .item {
                height: 25px;
                border: 1px solid rgba(51, 51, 51, .45);
                color: rgba(51, 51, 51, .45);
                padding: 0 15px;
                font-size: 13px;
                border-radius: 100px;
                line-height: 23px;
                margin-right: 10px;
            }
        }

        .buy {
            border: 1px solid #333;
            color: #333;
            width: 110px;
            height: 45px;
            border-radius: 22px;
            margin-right: 15px;
            font-size: 13px;
            transition: all ease .5s;
            box-shadow: 0px 3.48px 6.09px 0px rgba(0, 0, 0, 0.07);


            &.tao::before {
                content: "";
                display: block;
                width: 21px;
                height: 22px;
                background: url("../../assets/static/icon/icon_tao.png") no-repeat center;
                background-size: 100%;
                margin-right: 5px;
                transition: all ease .5s;
            }

            &.jd::before {
                content: "";
                display: block;
                width: 25px;
                height: 26px;
                background: url("../../assets/static/icon/icon_jd.png") no-repeat center;
                background-size: 100%;
                margin-right: 5px;
                transition: all ease .5s;
            }

            &:hover {
                background-color: #333;
                color: #f9f9f9;
            }

            &.tao:hover::before {
                background-image: url("../../assets/static/icon/icon_tao-w.png");
            }

            &.jd:hover::before {
                background-image: url("../../assets/static/icon/icon_jd-w.png");
            }
        }

        .progress {
            width: 230px;
            height: 48px;
            font-size: 12px;
            color: rgba(51, 51, 51, .7);
            cursor: pointer;

            &::before {
                display: block;
                content: "";
                width: 25px;
                height: 25px;
                background: url("../../assets/static/icon/icon_wx.png") no-repeat center;
                background-size: 100%;
                margin-right: 10px;
            }

            &:hover .code_box {
                display: block;
            }
        }

        .code_box {
            display: none;
            background: #fff;
            border-radius: 10px;
            width: 220px;
            height: 220px;
            padding: 10px;
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            margin: auto;

            img {
                width: 200px;
                height: 200px;
                display: block;
            }

            &::before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 10px 10px;
                border-color: transparent transparent #fff;
                position: absolute;
                top: -8px;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }
}

@media only screen and (max-width: 980px) {
    .container {


        .goods_info {
            min-height: auto;
            padding: 50px 0;
        }

        .links {
            padding: 30px 0;
        }

        .detail_box {
            padding: 0;

            .goods_box {
                padding: 0;
                display: block;

                .icon_back {
                    left: 30px;
                    top: 130px;
                    width: 24px;
                    height: 24px;
                    bottom: auto;
                }

                .pic {
                    width: 300px;
                    margin: 0 auto 30px;
                    display: block;
                }

                .info {
                    width: 100vw;
                    padding: 0 15px;
                    text-align: center;

                    .series_name {
                        font-size: 10px;
                    }

                    .series {
                        font-size: 17px;
                        justify-content: center;
                    }
                }

                .title {
                    font-size: 36px;
                }

                .features {
                    flex-wrap: wrap;

                    img {
                        margin-left: 0;
                        width: 20%;
                    }
                }

                .targets {
                    flex-wrap: wrap;
                    justify-content: center;

                    .item {
                        font-size: 9px;
                        padding: 0 11px;
                        margin-bottom: 10px;
                        width: auto;
                        height: 32px;
                        line-height: 30px;
                    }
                }

                .buy_box {
                    justify-content: center;

                    .buy {
                        width: 77px;
                        height: 31px;
                        font-size: 9px;
                    }

                    .buy.jd::before {
                        width: 15px;
                        height: 15px;
                    }

                    .buy.tao::before {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
}
</style>