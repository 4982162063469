import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import index from "../views/index.vue";

import trends from "../views/colorService/trends.vue";
import card from "../views/colorService/card.vue";
import match from "../views/colorService/match.vue";
import guide from "../views/colorService/guide.vue";
import styleDetail from "../views/colorService/styleDetail.vue";

import series from "../views/goods/series.vue";
import illustrate from "../views/goods/illustrate.vue";
import formula from "../views/goods/formula.vue";
import productDetail from "../views/goods/productDetail.vue";

import calculate from "../views/tools/calculate.vue";
import shop from "../views/tools/shop.vue";
import security from "../views/tools/security.vue";
import map from "../views/tools/map.vue";

import story from "../views/about/story.vue";
import authentication from "../views/about/authentication.vue";
import contact from "../views/about/contact.vue";
import cooperation from "../views/about/cooperation.vue";

import casePage from "../views/casePage.vue";
import caseDetail from "../views/caseDetail.vue";

const routes = [
	{
		path: "/",
		name: "index",
		component: index,
	},
	{
		path: "/series",
		name: "series",
		component: series,
	},
	{
		path: "/illustrate",
		name: "illustrate",
		component: illustrate,
	},
	{
		path: "/formula",
		name: "formula",
		component: formula,
	},
	{
		path: "/productDetail",
		name: "productDetail",
		component: productDetail,
	},
	{
		path: "/trends",
		name: "trends",
		component: trends,
	},
	{
		path: "/card",
		name: "card",
		component: card,
	},
	{
		path: "/match",
		name: "match",
		component: match,
	},
	{
		path: "/guide",
		name: "guide",
		component: guide,
	},
	{
		path: "/story",
		name: "story",
		component: story,
	},
	{
		path: "/authentication",
		name: "authentication",
		component: authentication,
	},
	{
		path: "/contact",
		name: "contact",
		component: contact,
	},
	{
		path: "/cooperation",
		name: "cooperation",
		component: cooperation,
	},
	{
		path: "/casePage",
		name: "casePage",
		component: casePage,
	},
	{
		path: "/calculate",
		name: "calculate",
		component: calculate,
	},
	{
		path: "/shop",
		name: "shop",
		component: shop,
	},
	{
		path: "/security",
		name: "security",
		component: security,
	},
	{
		path: "/map",
		name: "map",
		component: map,
	},
	{
		path: "/caseDetail",
		name: "caseDetail",
		component: caseDetail,
	},
	{
		path: "/styleDetail",
		name: "styleDetail",
		component: styleDetail,
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// 滚动到顶部
		return { top: 0 };
	},
});

export default router;
