<template>
    <Header></Header>
    <div class="container">
        <div class="page_title m1620">
            <div class="fs15">SIGNEO COLOR</div>
            <div class="fs64 fwb">诗珑色彩</div>
        </div>
        <div class="flex-box navigator_box m1620">
            <router-link :to="{ name: 'trends' }" class="item ">色彩趋势</router-link>
            <router-link :to="{ name: 'card' }" class="item active">色卡查询</router-link>
            <router-link :to="{ name: 'match' }" class="item">色系搭配</router-link>
            <router-link :to="{ name: 'guide' }" class="item">风格指南</router-link>
        </div>
        <div class="m1620">
            <div class="card_box">
                <div class="fs20 fwb">热门色卡</div>
                <div class="hot_box flex-box flex-col-start flex-wrap" v-if="recommendCardList.length > 0">
                    <div class="item" v-for="(item, index) in recommendCardList" :key="index"
                        @click="chooseCard = item, popupVisible = true">
                        <div class="top flex-box flex-center"
                            :style="{ backgroundColor: 'rgb(' + item.rgb_r + ',' + item.rgb_g + ',' + item.rgb_b + ')' }">
                            <img :src="item.image" alt="">
                        </div>
                        <div class="pt10 pl15">
                            <div class="fs24 col3 fwb pb5">Signeo</div>
                            <div class="col7d"><span class="fs14"> {{ item.rgb_title_cn }}</span></div>
                        </div>
                    </div>
                </div>
                <div class="noData" v-else>
                    <img :src="emptyImg" alt="">
                </div>
                <div class="fs20 fwb">色卡列表</div>
                <div class="fs17 col3 pt40 pb30">您可以直接搜索需要的色号...</div>
                <div class="flex-box">
                    <input type="text" class="search_input" placeholder="输入色号..." v-model="keywords">
                    <div class="search cursor" @click="colorCardList(0)"></div>
                </div>
                <div class="also fs22">也可以直接选择需要的色系...</div>
                <div class="color_list flex-box">
                    <div class="item" style="background: conic-gradient(from 180deg at 50% 50%, #FF3F4A -5.6deg, #FF35A2 24.48deg, #E8FFB8 54.06deg,
                     #ADFFFF 111.4deg, #A0B5FF 203.2deg, #FF7A00 291.4deg, #FF3F4A 354.4deg, #FF35A2 384.48deg);" @click="seriesColorIndex = -1,colorCardList(0)"></div>
                    <div class="item" v-for="(item, index) in seriesColor" @click="chooseSeries(index)"
                        :class="seriesColorIndex == index ? 'active' : ''" :key="index"
                        :style="{ backgroundColor: 'rgb(' + item.color_r + ',' + item.color_g + ',' + item.color_b + ')' }">
                    </div>
                </div>
                <!-- <div class="color_card_list show" v-if="!showCards"></div> -->
                <div class="color_card_list showCards show">
                    <div class="flex-box flex-wrap flex-col-start color_detail_box" v-if="cardList.length > 0">
                        <div class="color_detail" v-for="(item, index) in cardList" :key="index"
                            @click="chooseCard = item, popupVisible = true">
                            <div class="top"
                                :style="{ backgroundColor: 'rgb(' + item.rgb_r + ',' + item.rgb_g + ',' + item.rgb_b + ')' }">
                            </div>
                            <div class="pt5 plr8 m-ellipsis-l2">
                                <!-- <div class="flex-grow-1 fs10 col9">{{item.rgb_title_en}}</div> -->
                                <div class="fs11 col6 tr">{{ item.rgb_title_cn }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="noData" v-else>
                        <img :src="emptyImg" alt="">
                    </div>
                    <div class="page_box" style="height:240px">
                        <Pagination v-if="cardTotal > cardPageSize" :page="cardPageNum" :sumCount="cardTotal"
                            :perCount="cardPageSize" @pageTurn="colorCardList" ref="cardPagination" class="pagination">
                        </Pagination>
                    </div>
                </div>
                <div class="fs20 fwb pl10">新艺术涂料电子色卡</div>
                <div class="pdf_box flex-box flex-col-start flex-wrap" v-if="pdfList.length > 0">
                    <a :href="item.pdf_url" target="_blank" class="item" v-for="(item, index) in pdfList" :key="index">
                        <img :src="item.image" alt="" class="img">
                        <div class="flex-box plr25">
                            <div class="flex-grow-1">
                                <div class="series">{{ item.series.series_name_en }}</div>
                                <div class="name">{{ item.goods_name }}</div>
                            </div>
                            <div class="flex-box btn">
                                <img src="../../assets/static/icon/icon_pdf.png" class="icon_pdf" alt="">
                                <span>下载</span>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="noData" v-else>
                    <img :src="emptyImg" alt="">
                </div>
                <div class="page_box">
                    <Pagination v-if="pdfTotal > pdfPageSize" :page="pdfPageNum" :sumCount="pdfTotal"
                        :perCount="pdfPageSize" @pageTurn="pdf" class="pagination"></Pagination>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
    <popup :visible="popupVisible" :confirmText="false" :cancelText="false" @click="popupVisible = false">
        <div class="card_detail">
            <div class="color"
                :style="{ backgroundColor: 'rgb(' + chooseCard.rgb_r + ',' + chooseCard.rgb_g + ',' + chooseCard.rgb_b + ')' }">
            </div>
            <div class="detail flex-box flex-between flex-col-start">
                <div class="fs38 col38"></div>
                <div class="tr">
                    <div class="fs38 col3">{{ chooseCard.rgb_title_cn }}</div>
                    <div class="fs24 col38 mt15">{{ chooseCard.rgb_title_en }}</div>
                </div>
            </div>
        </div>
        <i class="icon_close"></i>
    </popup>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute();
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "色卡查询_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});

// 接收传递过来的下标
onMounted(() => {
    RecommendColorCardList();
    colorCardList();
    pdf();
})
// 修改下标
onBeforeRouteUpdate((to) => {
    RecommendColorCardList();
    colorCardList();
    pdf();
});


const emptyImg = JSON.parse(localStorage.getItem("emptyImg"));

// 获取推荐色卡与色卡分类
let recommendCardList = ref([]);//推荐色卡
let showCards = ref(false);//是否显示色卡列表
let seriesColor = ref([]);//色系列表
let seriesColorIndex = ref(-1);//色系下标
const RecommendColorCardList = async () => {
    try {
        const res = await api.getColorCardRes(); // 调用接口
        recommendCardList.value = res.data.recommends;
        seriesColor.value = res.data.category;
    } catch (error) {
        console.error(error);
    }
}
const chooseSeries = (index) => {
    seriesColorIndex.value = index;
    colorCardList(0);
    cardPageNum.value = 0;
}
let keywords = ref('');//关键词
let cardList = ref([]);//色卡列表
let cardPageSize = ref(0);
let cardTotal = ref(0);
let cardPageNum = ref(0);//当前页
const colorCardList = async (e) => {
    try {
        cardPageNum.value = e;
        const res = await api.getColorCardList({
            page: e ? e + 1 : 1,
            // color: seriesColor.value[seriesColorIndex.value],
            category_id: seriesColorIndex.value == -1 ? 0 : seriesColor.value[seriesColorIndex.value].id,
            keywords: keywords.value,
        }); // 调用接口
        cardList.value = [];
        cardList.value = res.data.data;
        cardPageSize.value = res.data.per_page;
        cardTotal.value = res.data.total;
    } catch (error) {
        console.error(error);
    }
}
let pdfList = ref([]);//pdf列表
let pdfPageSize = ref(0);
let pdfTotal = ref(0);
let pdfPageNum = ref(0);
const pdf = async (e) => {
    try {
        pdfPageNum.value = e;
        const res = await api.getPDF({
            page: e ? e + 1 : 1,
        }); // 调用接口
        pdfList.value = [];
        pdfList.value = res.data.data;
        pdfPageSize.value = res.data.per_page;
        pdfTotal.value = res.data.total;
    } catch (error) {
        console.error(error);
    }
}

let popupVisible = ref(false);//色卡详情
let chooseCard = ref({});//选中的色卡

</script>


<style lang="less" scoped>
.dialog_body {
    .card_detail {
        width: 515.53px;
        height: 627px;
        border-radius: 22.13px;
        background: #fff;
        overflow: hidden;
        line-height: 1;

        .color {
            width: 100%;
            height: 443.41px;
        }

        .detail {
            padding: 19.59px 30.91px;
        }

        .col38 {
            color: rgba(51, 51, 51, 0.8);
        }
    }

    .icon_close {
        width: 57px;
        height: 57px;
        display: block;
        margin: 68px auto 0;
        cursor: pointer;
        background: url("../../assets/static/icon/icon_close.png") no-repeat center;
        background-size: 100%;
    }
}

.card_box {
    padding: 120px 0 0 0;

    .hot_box {
        padding-top: 45px;
        padding-bottom: 60px;

        .item {
            width: 264px;
            height: 314px;
            border-radius: 16px;
            background: #fff;
            margin-right: 30px;
            margin-bottom: 30px;
            cursor: pointer;

            .top {
                width: 100%;
                height: 218px;
                border-radius: 16px 16px 0 0;
                position: relative;

                img {
                    max-width: inherit;
                    height: 218px;
                    width: auto;
                    position: relative;
                    top: -50px;
                }
            }

        }
    }

    .search_input {
        width: 180px;
        height: 34px;
        border-radius: 100px;
        border: 1px solid #ABABAB;
        padding: 0 15px;
    }

    .search {
        width: 76px;
        height: 34px;
        border-radius: 100px;
        background: #343434;
        background-image: url('../../assets/static/icon/icon_search.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 17px 17px;
        margin-left: 5px;
    }

    .also {
        background: linear-gradient(90deg, #BE1098 0%, #0368FF 55.5%, #9F03FF 91%);
        font-size: 22px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.03em;
        text-align: left;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        display: inline-block;
        padding: 60px 0 0;
    }

    .color_list {
        padding: 50px 0;

        .item {
            width: 45px;
            height: 45px;
            cursor: pointer;
            transition: all ease-in .5s;

            // &:nth-of-type(1) {
            //     background: linear-gradient(180deg, #E21737 0%, #FFA4D5 100%);
            // }

            // &:nth-of-type(2) {
            //     background: linear-gradient(180deg, #F1902B 0%, #F17E2B 100%);
            // }

            // &:nth-of-type(3) {
            //     background: linear-gradient(180deg, #FECD2D 0%, #FFFBD7 100%);
            // }

            // &:nth-of-type(4) {
            //     background: linear-gradient(180deg, #AAD85E 0%, #107500 100%);
            // }

            // &:nth-of-type(5) {
            //     background: linear-gradient(180deg, #789BF8 0%, #3264E4 100%);
            // }

            // &:nth-of-type(6) {
            //     background: linear-gradient(180deg, #D39BFF 0%, #43006B 100%);
            // }

            &:hover,
            &.active {
                transform: scale(1.2);
                border-radius: 6px;
            }
        }
    }

    .color_card_list {
        // height: 0;
        overflow: hidden;
        transition: all ease-in .5s;

        &.show {
            // height: 840px;
        }
    }

    .color_detail {
        border-radius: 20px;
        background-color: #fff;
        width: 145px;
        height: 128px;
        overflow: hidden;
        margin-right: 12px;
        margin-bottom: 12px;
        cursor: pointer;

        .top {
            height: 82px;
            width: 100%;
        }
    }

    .pdf_box {
        padding-top: 50px;

        .item {
            width: 354px;
            height: 260px;
            border-radius: 21px;
            background: #fff;
            margin-right: 30px;
            margin-bottom: 30px;

            .img {
                height: 145px;
                width: 100%;
                object-fit: cover;
                margin-bottom: 23px;
            }

            .series {
                font-size: 13px;
                font-weight: 400;
                line-height: 18.82px;
                color: rgba(51, 51, 51, 0.8);
                ;
            }

            .name {
                font-size: 19.59px;
                font-weight: 700;
                line-height: 28.36px;
            }

            .btn {
                box-shadow: 0px 3.52px 3.52px 0px rgba(0, 0, 0, 0.08);
                width: 80.29px;
                height: 32px;
                padding: 7.93px 9.69px 7.93px 9.69px;
                border-radius: 100px;
                background: #333333;
                color: #fff;

                .icon_pdf {
                    width: 18px;
                    height: 18px;
                    display: block;
                    margin-right: 8.81px;
                }
            }
        }
    }
}


@media only screen and (max-width: 980px) {

    .card_box {
        padding: 0;

        .fs17.col3.pt40.pb30 {
            font-size: 12px;
        }

        .hot_box {
            // justify-content: space-between;
            flex-wrap: nowrap;
            white-space: nowrap;
            width: 100%;
            overflow-x: scroll;
            padding-bottom: 30px;

            .item {
                display: block;
                width: 160px;
                margin-right: 10px;
                height: 200px;
                padding-bottom: 10px;

                .fs24 {
                    font-size: 20px;
                }

                .top {
                    width: 160px;
                    height: 100px;

                    img {
                        height: 100%;
                        top: -20px;
                    }
                }

                // &:nth-of-type(1) .top {

                //     img {
                //         width: 93px;
                //         right: -9px;
                //     }
                // }

                // &:nth-of-type(2) .top {

                //     img {
                //         width: 170px;
                //         right: -40px;
                //     }
                // }

                // &:nth-of-type(3) .top {

                //     img {
                //         width: 101px;
                //         right: -15px;
                //     }
                // }

                // &:nth-of-type(4) .top {

                //     img {
                //         width: 150px;
                //         right: -20px;
                //     }
                // }

                // &:nth-of-type(5) .top {

                //     img {
                //         width: 133px;
                //         right: 0px;
                //     }
                // }
            }
        }

        .search_input {
            width: 130px;
            height: 30px;
        }

        .search {
            height: 30px;
            width: 65px;
        }

        .also {
            padding: 50px 0 28px;
            font-size: 16px;
        }

        .color_list {
            padding: 62px 0 30px;
            flex-wrap: wrap;
            width: 100%;
        }

        .color_card_list {
            &.show {
                max-height: 3500px;
            }
        }

        .color_detail_box {

            .color_detail {
                height: 100px;
                margin-right: 0;
                width: calc((100vw - 60px) / 3);
                border-radius: 15px;
                margin-right: 15px;
                &:nth-of-type(3n){
                    margin-right: 0;
                }

                .top {
                    height: 65px;
                }

                .fs11 {
                    font-size: 9px;
                }

                .plr8 {
                    padding-left: 3px;
                    padding-right: 4px;
                }
            }
        }

        .pdf_box {
            padding-top: 50px;
            flex-wrap: wrap;
            justify-content: space-between;

            .item {
                width: calc((100vw - 45px)/2);
                height: 130px;
                border-radius: 21px;
                background: #fff;
                margin-right: 0;
                margin-bottom: 30px;
                border-radius: 15px;

                .img {
                    width: 100%;
                    height: 79px;
                    margin-bottom: 11px;
                }

                .plr25 {
                    padding: 0 11px;
                }

                .series {
                    font-size: 6px;
                    line-height: 6px;
                }

                .name {
                    font-size: 10px;
                }

                .btn {
                    width: 39px;
                    height: 16px;
                    font-size: 7px;
                    padding: 0 3px 0 4.5px;

                    .icon_pdf {
                        width: 9px;
                        height: 9px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }

    .dialog_body {
        .card_detail {
            width: 80vw;
            height: 400px;
            border-radius: 12.13px;

            .color {
                width: 100%;
                height: 300px;
            }
            .detail{
                padding: 15px 20px;
            }
            .fs38 {
                font-size: 24px;
            }

            .fs24 {
                font-size: 14px;
            }
        }

        .icon_close {
            width: 30px;
            height: 30px;
        }
    }
}
</style>