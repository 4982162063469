<template>
    <Header></Header>
    <div class="container">
        <div class="page_title m1620 pl0">
            <div class="fs15">ABOUT US</div>
            <div class="fs64 fwb">关于我们</div>
        </div>
        <div class="flex-box navigator_box m1620 pl0">
            <router-link :to="{ name: 'story' }" class="item ">品牌故事</router-link>
            <router-link :to="{ name: 'authentication' }" class="item ">环保认证</router-link>
            <router-link :to="{ name: 'contact' }" class="item">联系我们</router-link>
            <router-link :to="{ name: 'cooperation' }" class="item active">博主合作</router-link>
        </div>
        <div class="m1920">
            <div class="cooperation_box">
                <div class="fs16 tc col3 pb30 fwb">所属平台</div>
                <div class="flex-box flex-center cooperation_list flex-col-start">
                    <div class="cooperation_item" v-for="(item, index) in platformList" :key="index">
                        <img src="../../assets/static/icon/icon_cooper1.png" v-if="index == 0" class="icon_cooper" alt="">
                        <img src="../../assets/static/icon/icon_cooper2.png" v-if="index == 1" class="icon_cooper" alt="">
                        <img src="../../assets/static/icon/icon_cooper3.png" v-if="index == 2" class="icon_cooper" alt="">
                        <img src="../../assets/static/icon/icon_cooper4.png" v-if="index == 3" class="icon_cooper" alt="">
                        <img src="../../assets/static/icon/icon_cooper5.png" v-if="index == 4" class="icon_cooper" alt="">
                        <img src="../../assets/static/icon/icon_check-on.png" class="icon_check" alt=""
                            @click="item.choose = false" v-if="item.choose">
                        <div class="check_box" v-else @click="item.choose = true"></div>
                        <input type="text" :placeholder="`${item.name}ID：请输入`" class="id_input" v-if="item.choose" v-model="item.account_name">
                    </div>
                </div>
                <div class="fs16 tc col3 pt40 pb35 fwb">总粉丝量</div>
                <div class="flex-box flex-center">
                    <div class="fans_box" :class="fanIndex == 0 ? 'active' : ''" @click="fanIndex = 0">1w+</div>
                    <div class="fans_box" :class="fanIndex == 1 ? 'active' : ''" @click="fanIndex = 1">5w+</div>
                    <div class="fans_box" :class="fanIndex == 2 ? 'active' : ''" @click="fanIndex = 2">10w+</div>
                    <div class="fans_box" :class="fanIndex == 3 ? 'active' : ''" @click="fanIndex = 3">50w+</div>
                    <div class="fans_box" :class="fanIndex == 4 ? 'active' : ''" @click="fanIndex = 4">100w+</div>
                    <div class="fans_box" :class="fanIndex == 5 ? 'active' : ''" @click="fanIndex = 5">1000w+</div>
                </div>
                <div class="fs16 tc col3 pt40 pb35 fwb">合作邮箱</div>
                <input type="text" placeholder="请输入" class="email_input fs13" v-model="email">
                <div class="fs16 tc col3 pt40 pb35 fwb">备注</div>
                <textarea placeholder="请输入" class="bei_text fs13" v-model="note"></textarea>
                <div class="confirm flex-box flex-center cursor" @click="cooperation">确 认</div>
            </div>
        </div>
        <el-dialog v-model="state.dialogVisible" title="提示" :width="dialog_width">
            <span>{{ tips }}</span>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="state.dialogVisible = false">
                        确认
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
    <Footer></Footer>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute()
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "博主合作_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});
let dialog_width = ref('30%');
onMounted(() => {
    // 如果是h5则设置轮播一屏1.5个
    if (document.documentElement.clientWidth < 980) {
        dialog_width.value = '90%';
    }
})
// 设置粉丝量
let fanIndex = ref(0);
let platformList = ref([
    { name: '抖音', account_name: '', choose: false },
    { name: '小红书', account_name: '', choose: false },
    { name: '微博', account_name: '', choose: false },
    { name: 'B站', account_name: '', choose: false },
    { name: '微信视频号', account_name: '', choose: false },
])
let email = ref('');
let note = ref('');
let state = reactive({
    dialogVisible: false,
})
let tips = ref('');
const cooperation = async () => {
    try {
        let arr = [];
        platformList.value.forEach(item => {
            if (item.choose) {
                arr.push({
                    name: item.name,
                    account_name: item.account_name
                });
            }
        });
        if (arr.length == 0) {
            tips.value = '请选择平台账号';
            state.dialogVisible = true;
            return;
        } else {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].account_name == '') {
                    tips.value = '平台账号不能为空';
                    state.dialogVisible = true;
                    return;
                }
            }
        }
        if (email.value == '') {
            tips.value = '请输入合作邮箱';
            state.dialogVisible = true;
            return;
        }

        let data = {
            accouts: JSON.stringify(arr),
            fan_type: fanIndex.value + 1,
            cooperation_email: email.value,
            note: note.value,
        };
        const res = await api.saveCooperations(data); // 调用接口
        tips.value = res.msg
        state.dialogVisible = true;
        if (res.code == 1) {
            platformList.value = [
                { name: '抖音', account_name: '', choose: false },
                { name: '小红书', account_name: '', choose: false },
                { name: '微博', account_name: '', choose: false },
                { name: 'B站', account_name: '', choose: false },
                { name: '微信视频号', account_name: '', choose: false },
            ];
            fanIndex.value = 0;
            email.value = '';
            note.value = '';
        }
    } catch (error) {
        console.error(error);
    }
}

</script>
<style lang="less" scoped>
.cooperation_box {
    width: 834px;
    background: #f9f9f9;
    box-shadow: 17px 12px 47px rgba(0, 0, 0, .05) inset;
    border-radius: 46px;
    margin: 120px auto 0;
    padding: 90px 0 110px;

    .cooperation_item {
        margin: 0 7.5px;
        width: 122px;
        height: 146px;
    }

    .icon_cooper {
        width: 70px;
        height: 70px;
        display: block;
        margin: 0 auto;
    }

    .icon_check {
        width: 14px;
        height: 14px;
        display: block;
        margin: 7px auto 0;
        border-radius: 2px;
        cursor: pointer;
    }

    .check_box {
        width: 14px;
        height: 14px;
        display: block;
        margin: 7px auto 0;
        border: 1px solid #121212;
        border-radius: 2px;
        cursor: pointer;
    }

    .id_input {
        width: 122px;
        height: 38px;
        border: 1px solid #000000;
        border-radius: 100px;
        padding: 0 10px;
        margin-top: 17px;
        font-size: 12px;
    }

    .fans_box {
        width: 62px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border-radius: 5.5px;
        background: linear-gradient(#fff 0%, #FFFCFC 69%, #ECECEC 100%);
        font-size: 14px;
        font-weight: bold;
        margin: 0 2px;
        cursor: pointer;

        &.active,
        &:hover {
            background: #000;
            color: #fff;
        }
    }

    .email_input {
        width: 213px;
        height: 32px;
        border: 1px solid #000000;
        border-radius: 100px;
        padding: 0 10px;
        margin: 0 auto;
        display: block;
    }

    .bei_text {
        width: 213px;
        height: 61px;
        border: 1px solid #000000;
        border-radius: 12px;
        padding: 0 10px;
        margin: 0 auto;
        display: block;
        background: #f9f9f9;
        padding-top: 6px;
    }

    .confirm {
        width: 118px;
        height: 44px;
        border: 1px solid #000000;
        font-size: 14px;
        margin: 86px auto 0;
        border-radius: 100px;
        box-shadow: 0 3.4px 6px rgba(0, 0, 0, .1);
    }
}

@media only screen and (max-width: 980px) {
    .cooperation_box {
        width: auto;
        margin: 0;
        padding: 50px 0;
        border-radius: 0px;

        .cooperation_list {
            flex-wrap: wrap;
        }
    }
}
</style>