<template>
  <div class="header " :class="isIndex ? 'white' : ''">
    <div class="flex-box header_box fs14 m1620">
      <img src="../assets/static/icon/icon_logo-w.png" v-if="isIndex" class="logo" alt="">
      <img src="../assets/static/icon/icon_logo.png" v-else class="logo" alt="">
      <img src="../assets/static/icon/logo-w.png" v-if="isIndex" class="logo mobile" alt="">
      <img src="../assets/static/icon/logo.png" v-else class="logo mobile" alt="">
      <div class="flex-box flex-grow-1 menus">
        <router-link class="nav flex-grow-1" to="/">
          <div class="menu">首页</div>
        </router-link>
        <div class="nav cursor flex-grow-1">
          <div class="menu" @click="showChildren(0)">色彩服务</div>
          <div class="nav_list" :class="choosePhoneIndex == 0 ? 'show' : ''">
            <router-link class="item" :to="{ name: 'trends' }" @click.stop="hideList()">色彩趋势</router-link>
            <router-link class="item" :to="{ name: 'card' }" @click.stop="hideList()">色卡查询</router-link>
            <router-link class="item" :to="{ name: 'match' }" @click.stop="hideList()">色系搭配</router-link>
            <router-link class="item" :to="{ name: 'guide' }" @click.stop="hideList()">风格指南</router-link>
          </div>
        </div>
        <div class="nav cursor flex-grow-1">
          <div class="menu" @click="showChildren(1)">产品服务</div>
          <div class="nav_list" :class="choosePhoneIndex == 1 ? 'show' : ''">
            <router-link class="item" :to="{ name: 'series' }" @click.stop="hideList()">产品体系</router-link>
            <router-link class="item" :to="{ name: 'illustrate' }" @click.stop="hideList()">施工说明</router-link>
            <router-link class="item" :to="{ name: 'formula' }" @click.stop="hideList()">调色配方</router-link>
          </div>
        </div>
        <router-link class="nav flex-grow-1" to="/casePage">
          <div class="menu">案例</div>
        </router-link>
        <div class="nav cursor flex-grow-1">
          <div class="menu" @click="showChildren(2)">实用工具</div>
          <div class="nav_list" :class="choosePhoneIndex == 2 ? 'show' : ''">
            <router-link class="item" :to="{ name: 'calculate' }" @click.stop="hideList()">漆量计算</router-link>
            <!-- <router-link class="item" :to="{ name: 'shop' }" @click.stop="hideList()">门店查询</router-link> -->
            <router-link class="item" :to="{ name: 'security' }" @click.stop="hideList()">防伪自查</router-link>
          </div>
        </div>
        <div class="nav cursor flex-grow-1">
          <div class="menu" @click="showChildren(3)">关于我们</div>
          <div class="nav_list" :class="choosePhoneIndex == 3 ? 'show' : ''">
            <router-link class="item" :to="{ name: 'story' }" @click.stop="hideList()">品牌故事</router-link>
            <router-link class="item" :to="{ name: 'authentication' }" @click.stop="hideList()">环保认证</router-link>
            <router-link class="item" :to="{ name: 'contact' }" @click.stop="hideList()">联系我们</router-link>
            <router-link class="item" :to="{ name: 'cooperation' }" @click.stop="hideList()">博主合作</router-link>
          </div>
        </div>
      </div>
      <div class="search cursor" @click="showSearch = !showSearch" v-if="!showSearch"></div>
      <div class="flex-box flex-grow-1 menus_phone" :class="[showPhoneMenu ? 'showPhone' : '', isIndex ? '' : 'white']">
        <router-link class="nav_phone" to="/">
          <div class="menu">首页</div>
        </router-link>
        <div class="nav_phone cursor">
          <div class="menu arrow flex-box flex-between" :class="choosePhoneIndex == 0 ? 'active' : ''"
            @click="showChildren(0)">色彩服务</div>
          <div class="nav_list" v-if="choosePhoneIndex == 0">
            <router-link class="item" :to="{ name: 'trends' }">色彩趋势</router-link>
            <router-link class="item" :to="{ name: 'card' }">色卡查询</router-link>
            <router-link class="item" :to="{ name: 'match' }">色系搭配</router-link>
            <router-link class="item" :to="{ name: 'guide' }">风格指南</router-link>
          </div>
        </div>
        <div class="nav_phone cursor">
          <div class="menu arrow flex-box flex-between" :class="choosePhoneIndex == 1 ? 'active' : ''"
            @click="showChildren(1)">产品服务</div>
          <div class="nav_list" v-if="choosePhoneIndex == 1">
            <router-link class="item" :to="{ name: 'series' }">产品体系</router-link>
            <router-link class="item" :to="{ name: 'illustrate' }">施工说明</router-link>
            <router-link class="item" :to="{ name: 'formula' }">调色配方</router-link>
          </div>
        </div>
        <router-link class="nav_phone" to="/casePage">
          <div class="menu">案例</div>
        </router-link>
        <div class="nav_phone cursor">
          <div class="menu arrow flex-box flex-between" :class="choosePhoneIndex == 2 ? 'active' : ''"
            @click="showChildren(2)">实用工具</div>
          <div class="nav_list" v-if="choosePhoneIndex == 2">
            <router-link class="item" :to="{ name: 'calculate' }">漆量计算</router-link>
            <!-- <router-link class="item" :to="{ name: 'shop' }">门店查询</router-link> -->
            <router-link class="item" :to="{ name: 'security' }">防伪自查</router-link>
          </div>
        </div>
        <div class="nav_phone cursor">
          <div class="menu arrow flex-box flex-between" :class="choosePhoneIndex == 3 ? 'active' : ''"
            @click="showChildren(3)">关于我们</div>
          <div class="nav_list" v-if="choosePhoneIndex == 3">
            <router-link class="item" :to="{ name: 'story' }">品牌故事</router-link>
            <router-link class="item" :to="{ name: 'authentication' }">环保认证</router-link>
            <router-link class="item" :to="{ name: 'contact' }">联系我们</router-link>
            <router-link class="item" :to="{ name: 'cooperation' }">博主合作</router-link>
          </div>
        </div>
      </div>
      <img src="../assets/static/icon/menu-w.png" v-if="isIndex" class="icon_menu" alt=""
        @click="showPhoneMenu = !showPhoneMenu">
      <img src="../assets/static/icon/menu.png" v-else class="icon_menu" alt="" @click="showPhoneMenu = !showPhoneMenu">
    </div>
    <div class="mask" :class="showPhoneMenu ? 'show' : ''" @click="showPhoneMenu = false" v-if="!isIndex"></div>
    <!-- 搜索弹窗 -->
    <div class="search_view" :class="showSearch ? 'show' : ''">
      <div class="m1440">
        <div class="flex-box search_box">
          <input type="text" placeholder="搜索关键字" @keyup.enter="search(0)" v-model="keywords" class="input flex-grow-1">
          <div class="search cursor" @click="search(0)"></div>
        </div>
        <div class="nav_box pt40 flex-box flex-col-start" v-if="!state.showRes">
          <div class="item">
            <div class="fs16 fwb pb5 cursor">色彩服务</div>
            <router-link class="link fs14" :to="{ name: 'trends' }">色彩趋势</router-link>
            <router-link class="link fs14" :to="{ name: 'card' }">色卡查询</router-link>
            <router-link class="link fs14" :to="{ name: 'match' }">色系搭配</router-link>
            <router-link class="link fs14" :to="{ name: 'guide' }">风格指南</router-link>
          </div>
          <div class="item">
            <div class="fs16 fwb pb5 cursor">产品服务</div>
            <router-link class="link fs14" :to="{ name: 'series' }">产品体系</router-link>
            <router-link class="link fs14" :to="{ name: 'illustrate' }">施工说明</router-link>
            <router-link class="link fs14" :to="{ name: 'formula' }">调色配方</router-link>
          </div>
          <div class="item">
            <div class="fs16 fwb pb5 cursor">实用工具</div>
            <router-link class="link fs14" :to="{ name: 'calculate' }">漆量计算</router-link>
            <!-- <router-link class="link fs14" :to="{ name: 'shop' }">门店查询</router-link> -->
            <router-link class="link fs14" :to="{ name: 'security' }">防伪自查</router-link>
          </div>
          <div class="item">
            <div class="fs16 fwb pb5 cursor">关于我们</div>
            <router-link class="link fs14" :to="{ name: 'story' }">品牌故事</router-link>
            <router-link class="link fs14" :to="{ name: 'authentication' }">环保认证</router-link>
            <router-link class="link fs14" :to="{ name: 'contact' }">联系我们</router-link>
            <router-link class="link fs14" :to="{ name: 'cooperation' }">博主合作</router-link>
          </div>
        </div>
        <div class="flex-box" v-if="state.showRes">
          <div class="titles fs20 cursor" :class="type == 1 ? 'active' : ''" @click="changeType(1)">产品</div>
          <div class="titles fs20 cursor" :class="type == 2 ? 'active' : ''" @click="changeType(2)">色卡</div>
          <div class="titles fs20 cursor" :class="type == 3 ? 'active' : ''" @click="changeType(3)">信息</div>
          <div class="titles fs20 cursor" :class="type == 4 ? 'active' : ''" @click="changeType(4)">案例</div>
        </div>
        <div class="box" v-if="state.showRes">
          <div class="noData" v-if="resList.length == 0">
            <img :src="emptyImg" alt="">
          </div>
          <div class="flex-box flex-wrap flex-col-start" v-if="type == 1">
            <router-link :to="{ name: 'productDetail', query: { id: item.id } }" @click="showSearch = false"
              class="goods_item shadow" v-for="(item, index) in resList" :key="index">
              <div class="colc fs10 pb5" v-if="item.series">{{ item.series.series_name_en }}</div>
              <div class="col3 fs14" v-if="item.series">Signeo {{ item.series.series_name_cn }}</div>
              <div class="col3 fs22 fwb m-ellipsis">{{ item.goods_name }}</div>
              <div class="to_detail">查看详情 >></div>
              <img :src="item.image" class="pic" alt="" />
              <div class="view flex-box">{{ item.views }}</div>
            </router-link>
          </div>
          <div class="flex-box flex-wrap flex-col-start" v-if="type == 2">
            <div class="color_detail" @click="chooseCard = item, popupVisible = true" v-for="(item, index) in resList" :key="index">
              <div class="top"
                :style="{ backgroundColor: 'rgb(' + item.rgb_r + ',' + item.rgb_g + ',' + item.rgb_b + ')' }"></div>
              <div class="pt5 plr8 m-ellipsis-l2">
                <div class="fs11 col6 tr">{{ item.rgb_title_cn }}</div>
              </div>
            </div>
          </div>
          <div class="flex-box flex-wrap flex-col-start" v-if="type == 3">
            <a :href="item.url" target="_blank" class="intro_item shadow" v-for="(item, index) in  resList " :key="index">
              <img :src="item.image_text" class="pic" alt="" />
              <div class="info">
                <div class="col3 fs22 fwb title m-ellipsis-l2">{{ item.name }}</div>
                <div class="flex-box pt15" v-if="item.authors">
                  <img :src="item.authors.image" class="avatar" alt="" />
                  <div class="flex-grow-1 m-ellipsis fs10 col7 pl10">{{ item.authors.author_name }}</div>
                  <div class="col9 fs10 detail flex-box">查看详情</div>
                </div>
              </div>
            </a>
          </div>
          <div class="flex-box flex-wrap flex-col-start" v-if="type == 4">
            <router-link :to="{ name: 'caseDetail', query: { id: item.id } }" class="intro_item shadow"
              v-for="( item, index ) in  resList " :key="index">
              <img v-if="item.images" :src="item.images[0]" class="pic" alt="" />
              <div class="info">
                <div class="col3 fs22 fwb title m-ellipsis-l2">{{ item.subtitle }}</div>
                <div class="flex-box pt15" style="height:45px">
                  <!-- <img src="../assets/static/images/banner.png" class="avatar" alt="" />
                  <div class="flex-grow-1 m-ellipsis fs10 col7 pl10">Signeo诗珑服务中心</div> -->
                  <div class="flex-grow-1 m-ellipsis fs10 col7"></div>
                  <div class="col9 fs10 detail flex-box">查看详情</div>
                </div>
              </div>
            </router-link>
          </div>
          <Pagination v-if="total > pageSize" :page="pageNum" :sumCount="total" :perCount="pageSize" @pageTurn="search"
            class="pagination"></Pagination>
        </div>
        <div class="hide flex-box cursor" @click="showSearch = false">收起</div>
      </div>
    </div>
  </div>
  <popup :visible="popupVisible" :confirmText="false" :cancelText="false" @click="popupVisible = false">
    <div class="card_detail">
      <div class="color"
        :style="{ backgroundColor: 'rgb(' + chooseCard.rgb_r + ',' + chooseCard.rgb_g + ',' + chooseCard.rgb_b + ')' }">
      </div>
      <div class="detail flex-box flex-between flex-col-start">
        <div class="fs38 col38"></div>
        <div class="tr">
          <div class="fs38 col3">{{ chooseCard.rgb_title_cn }}</div>
          <div class="fs24 col38 mt15">{{ chooseCard.rgb_title_en }}</div>
        </div>
      </div>
    </div>
    <i class="icon_close"></i>
  </popup>
</template>
<script setup>
import { ref, reactive, onMounted, onBeforeUnmount, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
let keywords = ref('');//关键字
let type = ref(1);//1商品 2色卡 3信息 4案例
let resList = ref([]);//搜索结果
let pageNum = ref(0);//当前页数
let pageSize = ref(0);//每页有多少条数据
let total = ref(0);//一共有多少条数据
let state = reactive({//是否显示搜索结果
  showRes: false
})
let choosePhoneIndex = ref(-1);
// 定义接收的 props
const props = defineProps({
  isIndex: {
    type: Boolean,
    default: false
  },
});
// 处理点击空白处关闭导航栏
const handleClickOutside = (event) => {
  if (!event.target.closest('.nav, .menu.arrow')) {
    choosePhoneIndex.value = -1;
  }
};
const emptyImg = JSON.parse(localStorage.getItem("emptyImg"));
// 监听点击事件
onMounted(() => {
  window.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  window.removeEventListener('click', handleClickOutside);
});

const changeType = (index) => {
  type.value = index;
  pageNum.value = 0;
  search(0);
}
//搜索
const search = async (e) => {
  try {
    pageNum.value = e;
    const res = await api.searchList({
      type: type.value,
      page: e ? e + 1 : 1,
      keywords: keywords.value,
    }); // 调用接口
    state.showRes = true;
    resList.value = res.data.data;
    pageSize.value = res.data.per_page;
    total.value = res.data.total;
  } catch (error) {
    console.error(error);
  }
}
let showSearch = ref(false);
let showPhoneMenu = ref(false);

// 选择显示的子页面
const showChildren = (index) => {
  if (index == choosePhoneIndex.value) {
    choosePhoneIndex.value = -1;
  } else {
    choosePhoneIndex.value = index;
  }
}
// 隐藏子路由
const hideList = () => {
  choosePhoneIndex.value = -1;
}

let popupVisible = ref(false);//色卡详情
let chooseCard = ref({});//选中的色卡

</script>
<style lang="less" scoped>
.dialog_body {
    .card_detail {
        width: 515.53px;
        height: 627px;
        border-radius: 22.13px;
        background: #fff;
        overflow: hidden;
        line-height: 1;

        .color {
            width: 100%;
            height: 443.41px;
        }

        .detail {
            padding: 19.59px 30.91px;
        }

        .col38 {
            color: rgba(51, 51, 51, 0.8);
        }
    }

    .icon_close {
        width: 57px;
        height: 57px;
        display: block;
        margin: 68px auto 0;
        cursor: pointer;
        background: url("../assets/static/icon/icon_close.png") no-repeat center;
        background-size: 100%;
    }
}
.header {
  background: rgba(249, 249, 249, .8);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  transition: all ease .5s;
  // padding: 0 150px;

  .header_box {
    height: 80px;
    position: relative;
    z-index: 20;

    .menus_phone,
    .icon_menu {
      display: none;
    }

    .logo {
      width: 100px;
      height: 29px;
      display: block;
      margin-right: 24px;

      &.mobile {
        display: none;
      }
    }

    .nav {
      height: 80px;
      text-align: center;
      line-height: 80px;
      color: #4A4A4A;
      position: relative;

      .menu {
        position: relative;
        z-index: 3;
      }

      &:hover {
        color: #000;
        position: relative;
      }

      &::after {
        content: "";
        display: block;
        opacity: 0;
        width: 0;
        height: 5px;
        border-radius: 100px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 28px;
        margin: auto;
        z-index: 0;
        transition: all ease .5s;
      }

      &:hover::after {
        width: 72px;
        background: linear-gradient(#FFA2FB, #0017E3);
        opacity: 1;
      }

      .nav_list {
        position: absolute;
        top: -99em;
        width: 100%;
        background: linear-gradient(rgba(244, 244, 244, .1) 0%, rgba(248, 248, 248.42) 42%, #fff 100%);
        padding-top: 88px;
        padding-bottom: 18px;
        z-index: 2;
        transition: all ease .3s;

        .item {
          display: block;
          color: #636363;
          height: 55px;
          line-height: 55px;

          &:hover {
            color: #333;
          }
        }

        &.show {
          top: 0;
        }
      }
    }

    .search {
      width: 64px;
      height: 30px;
      border-radius: 100px;
      background: #343434;
      background-image: url('../assets/static/icon/icon_search.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 17px 17px;
      margin-left: 24px;
    }
  }

  .search_view {
    background: #f9f9f9;
    position: absolute;
    top: -200vh;
    left: 0;
    right: 0;
    border-radius: 0 0 35px 35px;
    padding: 110px 0 50px;
    z-index: 10;
    transition: top ease .5s;
    box-shadow: 1px 1px 10px rgba(52, 52, 52, .1);

    .input {
      height: 30px;
      border-bottom: 1px solid #333;
      margin-right: 40px;
    }

    .search {
      width: 64px;
      height: 30px;
      border-radius: 100px;
      background: #343434;
      background-image: url('../assets/static/icon/icon_search.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 17px 17px;
      margin-left: 24px;
    }

    .nav_box {
      height: 330px;

      .item {
        padding-right: 73px;

        .link {
          line-height: 2;
          display: block;
        }
      }
    }

    .titles {
      color: rgba(51, 51, 51, 0.398);
      padding: 30px 0 0;
      margin-right: 100px;

      &.active {
        color: #333;
      }
    }

    .box {
      height: 50vh;
      overflow-y: scroll;
      margin: 30px 0;

      &::-webkit-scrollbar {
        display: block;
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #333;
        border-radius: 10px;
      }



      .goods_item {
        box-shadow: 0 3.6px 10px rgba(0, 0, 0, 0.08);
        width: 288px;
        height: 344px;
        border-radius: 28px;
        background: #fffefd;
        margin-right: 15px;
        margin-bottom: 15px;
        padding: 16px 18px 0;
        position: relative;
        overflow: hidden;

        .to_detail {
          width: 88px;
          height: 24px;
          border: 1px solid #c5c4c4;
          color: #c5c4c4;
          line-height: 22px;
          text-align: center;
          font-size: 10px;
          border-radius: 100px;
          margin-top: 12px;
        }

        .pic {
          width: 400px;
          height: auto;
          position: absolute;
          right: -90px;
          bottom: -108px;
          max-width: inherit;
        }

        .view {
          position: absolute;
          left: 18px;
          bottom: 15px;
          font-size: 10px;
          color: #b2b2b2;

          &::before {
            content: "";
            display: block;
            background: url("../assets/static/icon/icon_view.png") no-repeat center;
            background-size: 100%;
            width: 19px;
            height: 11px;
            margin-right: 5px;
          }
        }
      }

      .intro_item {
        width: 320px;
        height: 320px;
        border-radius: 50px;
        background: #fefefe;
        margin-right: 15px;
        margin-bottom: 15px;
        box-shadow: 0 3.3px 10px rgba(0, 0, 0, 0.05);

        .pic {
          width: 320px;
          height: 180px;
          display: block;
          object-fit: cover;
          border-radius: 50px 50px 0 0;
        }

        .info {
          padding: 12px 26px 0;

          .title {
            line-height: 30px;
            height: 60px;
            padding: 0;
            color: #333;
            width: auto;
          }
        }

        .avatar {
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .detail::after {
          content: "";
          display: block;
          width: 6px;
          height: 10.5px;
          background: url("../assets/static/icon/icon_arrow-r.png") no-repeat center;
          background-size: 100%;
          margin-left: 7px;
        }
      }

      .color_detail {
        border-radius: 20px;
        background-color: #fff;
        width: 145px;
        height: 128px;
        overflow: hidden;
        margin-right: 12px;
        margin-bottom: 12px;
        cursor: pointer;

        .top {
          height: 82px;
          width: 100%;
        }
      }
    }


    .hide::before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 8px;
      border-color: transparent transparent #000;
      margin-right: 5px;
    }
  }

  .search_view.show {
    top: 0;
  }
}

.header.white {
  background: transparent;
  backdrop-filter: none;

  .header_box {
    .nav div {
      color: #fff;
    }

    .nav_list {
      background: linear-gradient(rgba(0, 0, 0, 0), #000);

      .item {
        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.mask {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  // opacity: 0;
  width: 100vw;
  height: calc(100vh - 50px);
  // transition: all ease .5s;
  display: none;

  &.show {
    // opacity: 1;
    display: block;
  }
}

@media only screen and (max-width: 1920px) {
  .header {
    // padding: 0 100px;
  }
}

@media only screen and (max-width: 1440px) {box
  .header {

    // padding: 0 50px;
    .header_box {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 980px) {
  .header {
    width: 100vw;
    background: rgb(249, 249, 249);
    padding: 0;

    .header_box {
      height: 50px;
      width: 100vw;
      margin: 0;
      padding: 0 15px;

      .logo {
        display: none;

        &.mobile {
          width: 56px;
          height: auto;
          display: block;
        }
      }

      .icon_menu {
        display: block;
        margin-left: auto;
        width: 36px;
        height: 36px;
      }

      .menus,
      .search {
        display: none;
      }

      .menus_phone.showPhone {
        display: block;
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        height: auto;
        width: 100vw;
        background: linear-gradient(rgba(0, 0, 0, 0), #000);

        .nav_phone {
          width: 100vw;
          height: auto;
          color: #fff;

          .menu {
            height: 50px;
            line-height: 50px;
            text-align: left;
            margin: 0 15px;
            border-bottom: 1px solid rgba(255, 255, 255, .3);

            &.arrow::after {
              content: "";
              display: block;
              width: 12.5px;
              height: 7px;
              background: url("../assets/static/icon/arrow.png") no-repeat center;
              background-size: 100%;
            }

            &.arrow.active::after {
              transform: rotate(180deg);
            }
          }

          .nav_list {
            background: transparent;

            .item {
              display: block;
              height: 50px;
              line-height: 50px;
              margin: 0 15px;
              font-size: 12px;
            }
          }

          &:last-child .menu {
            border: none;
          }

          &:last-child .menu~.nav_list {
            .item:first-of-type {
              border-top: 1px solid #fff;
            }
          }
        }

        &.white {
          background: #F9F9F9;

          .nav_phone {
            color: #000;

            .menu {
              border-color: rgba(51, 51, 51, .3);

              &.arrow::after {
                background-image: url("../assets/static/icon/arrow-b.png");
              }
            }

            &:last-child .menu~.nav_list {
              .item:first-of-type {
                border-top: 1px solid rgba(51, 51, 51, .3);
              }
            }
          }
        }
      }
    }
  }
}
</style>
