<template>
    <Header></Header>
    <div class="container">
        <div class="page_title m1620">
            <div class="fs15">ABOUT US</div>
            <div class="fs64 fwb">关于我们</div>
        </div>
        <div class="flex-box navigator_box m1620">
            <router-link :to="{ name: 'story' }" class="item active">品牌故事</router-link>
            <router-link :to="{ name: 'authentication' }" class="item ">环保认证</router-link>
            <router-link :to="{ name: 'contact' }" class="item">联系我们</router-link>
            <router-link :to="{ name: 'cooperation' }" class="item">博主合作</router-link>
        </div>
        <div class="story_box m1620">
            <div v-html="processHtml(brandsData.articles.detail)" v-if="brandsData.articles"></div>
        </div>
        <!-- <template v-if="storyList.length > 0"> -->
            <a :href="item.url" target="_blank" v-for="(item, index) in storyList" :key="index" class="history_a">
                <div class="m1920 flex-box flex-col-start history_item">
                    <img :src="item.image" class="history" alt="">
                    <div class="right flex-grow-1">
                        <div class="fs30 fwb title m-ellipsis">{{ item.name }}</div>
                        <div class="detail m-ellipsis-l3">{{ item.detail }}}</div>
                        <div class="flex-box flex-center to_detail cursor">查看详情</div>
                    </div>
                </div>
            </a>
            <div class="page_box">
                <Pagination  v-if="storyTotal > storyPageSize" :page="storyPageNum" :sumCount="storyTotal" :perCount="storyPageSize" @pageTurn="story" class="pagination">
                </Pagination>
            </div>
        <!-- </template> -->
    </div>
    <Footer></Footer>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute()
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "品牌故事_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});

// 处理 HTML 的方法
const processHtml = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const images = tempDiv.getElementsByTagName('img');
    for (let img of images) {
        img.style.width = '100%';
        img.style.height = 'auto'; // 可选，保持图片的比例
    }

    return tempDiv.innerHTML;
};
// 接收传递过来的下标
onMounted(() => {
    brands();
    story(0);
})
onBeforeRouteUpdate((to) => {
    brands();
});

// 品牌故事
let brandsData = ref({});
const brands = async () => {
    try {
        const res = await api.getBrands(); // 调用接口
        brandsData.value = res.data;
    } catch (error) {
        console.error(error);
    }
}

let storyPageNum = ref(0);
let storyTotal = ref(0);
let storyList = ref([]);
let storyPageSize = ref(0);
const story = async (e) => {
    try {
        const res = await api.getBrands({
            page: e ? e + 1 : 1,
        }); // 调用接口
        storyPageNum.value = e;
        storyList.value = res.data.lists.data;
        storyPageSize.value = res.data.lists.per_page;
        storyTotal.value = res.data.lists.total;
    } catch (error) {
        console.error(error);
    }
}
</script>
<style lang="less" scoped>
.story_box {
    padding: 120px 0;

    img {
        display: block;
        width: 100% !important;
        height: auto;
        margin: 0 auto;
    }
}

.history_a {
    display: block;
}

.history_a~.history_a {
    margin-top: 25px;
}

.history_item {
    background: #fff;
    height: 470px;

    .history {
        width: 760px;
        height: 470px;
        display: block;
        object-fit: cover;
    }

    .right {
        padding: 25px 45px 0;

        .title {
            height: 52px;
            line-height: 52px;
            margin-bottom: 6px;
        }

        .detail {
            font-size: 13px;
            margin-bottom: 6px;
            line-height: 23px;
            height: 69px;
        }

        .to_detail {
            border: 1px solid #000;
            width: 120px;
            height: 36px;
            font-size: 10px;
            border-radius: 100px;
            margin-top: 75px;

            &::after {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                background: url('../../assets/static/icon/icon_to.png') no-repeat center;
                background-size: 100%;
                margin-left: 3px;
            }
        }
    }
}


@media only screen and (max-width: 1440px) {
    .history_item {
        .history {
            width: 50%;
            height: 470px;
            display: block;
        }

        .right {
            padding-right: 45px;
        }
    }
}

@media only screen and (max-width: 980px) {
    .story_box {
        padding: 0;

        .flex-box {
            display: block;

            .flex-grow-1 {
                width: auto;
            }
        }

        .pl60 {
            padding-left: 0;
        }

        .pr60 {
            padding-right: 0;
        }

        img {
            display: block;
            margin: 0 auto;
        }

        .pt100 {
            padding-top: 30px;
        }

        .pb95 {
            padding-bottom: 30px;
        }
    }

    .history_item {
        display: block;
        height: auto;
        margin: 0;

        .history {
            width: 100%;
            height: 200px;
            display: block;
        }

        .right {
            padding: 10px 26px 28px;
            width: 100%;

            .title {
                font-size: 16px;
                height: 28px;
                line-height: 28px;
                margin-bottom: 0;
            }

            .detail {
                font-size: 8px;
                height: 48px;
                line-height: 16px;
                margin-bottom: 0;

            }
        }

        .to_detail {
            display: none;
        }
    }
}
</style>